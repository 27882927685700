import ApiNotificationConnector from './../api/notifications'

const apiNotification = new ApiNotificationConnector('notifications')

const proxy = ({fn, call, callback, uid}) => fn('makeCall', {namespace: 'notifications', promise: call, uid, callback}, {root: true})

export default {
  getNotifType: function ({dispatch, rootGetters}, {uid = 0}) {
    return proxy({fn: dispatch, call: apiNotification.getTypes(rootGetters['user/token']), uid})
  },
  getRegisteredNotifs: function ({dispatch, rootGetters}, {params, uid = 0}) {
    return proxy({fn: dispatch, call: apiNotification.getRegisteredNotifs(rootGetters['user/token'], params), uid})
  },
  registerNotifs: function ({dispatch, rootGetters}, data) {
    return proxy({fn: dispatch, call: apiNotification.registerNotifs(rootGetters['user/token'], data)})
  },
  getNotificationEmails: function ({dispatch, rootGetters}, {params, uid = 0}) {
    return proxy({fn: dispatch, call: apiNotification.getNotificationEmails(rootGetters['user/token'], params), uid})
  },
  getNotificationDailies: function ({dispatch, rootGetters}, {params, uid = 0}) {
    return proxy({fn: dispatch, call: apiNotification.getNotificationDailies(rootGetters['user/token'], params), uid})
  },
  getInformationMessages: function ({commit, dispatch, rootGetters}, params) {
    return proxy({fn: dispatch, call: apiNotification.getInformationMessages(rootGetters['user/token'], params)})
      .then(r => {
        commit('INFORMATION_MESSAGES_SUCCESS', r.data)
        return r
      })
  }
}
