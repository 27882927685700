import axios from 'axios'
import { api } from './../../config'
import store from '@/store'

/**
 * VideoApi
 *
 * Build an instance of VideoApi
 *
 * @return {Object}     VideoApi instance with method to video api
 */
export default function VideoApi () {
  const token = localStorage.getItem('fa7-token') || ''
  const headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: `Bearer ${token}`
  }

  return {
    /**
     * post a new VAST
     *
     * @param   {Object}    data              The vas data to send
     * @param   {Object}    params            The api call parameters
     * @return  {Promise}                     Resolves when request end
     */
    post (data, params = {}) {
      return axios({
        method: 'post',
        url: api.root + '/api/vastconfigs',
        data,
        headers
      })
    },

    /**
     * test a new VAST from xml url
     * @param   {Object}    data              The vas data to send
     * @param   {Object}    params            api parameters
     * @return  {Promise}                     Resolves when request end
     */
    test (data, params = {}, token) {
      const headers = {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      }
      return axios({
        method: 'post',
        url: api.root + '/api/vastconfigs/inspect',
        data,
        headers
      })
    },

    /**
     * Update an existing VAST
     *
     * @param   {Object}    data              The vas data to send
     * @param   {String}    id                The vast id
     * @return  {Promise}                     Resolves when request end
     */
    patch (data) {
      return axios({
        method: 'put', // patch generates a CORS error
        url: api.root + '/api/vastconfigs/' + data.id,
        data,
        headers
      })
    },

    /**
     * Reload the vast check
     *
     * @param   {String}    id    The vast id to re check
     * @return  {Promise}         Resolves when request end
     */
    put (id) {
      console.log(api.root + '/api/vastconfigs/' + id)
      return axios({
        method: 'put',
        url: api.root + '/api/vastconfigs/' + id,
        data: {
          // company: '2020-05-22-test'
        },
        headers
      })
    },

    /**
     * Fetch vasts checked from the API
     *
     * @param   {Object}    params            The api call parameters
     * @return  {Promise}                     Resolves when request end
     */
    fetchAll (params = { page: 1, limit: 15 }) {
      return axios({
        method: 'get',
        url: api.root + '/api/vastconfigs',
        params,
        headers
      })
    },

    /**
     * Fetch vasts checked from the API
     *
     * @param   {string}    Id                The item Id
     * @param   {Object}    params            The api call parameters
     * @return  {Promise}                     Resolves when request end
     */
    get ({ id, params = {} }) {
      return axios({
        method: 'get',
        url: api.root + '/api/vastconfigs/' + id,
        params,
        headers
      })
    },

    /**
     * Get sales type
     *
     * @return  {Promise}  Resolves when request end
     */
    saleTypes () {
      return axios({
        method: 'get',
        url: api.root + '/saletypes',
        headers
      })
    },

    /**
     * Get Advertisers related to requested agency
     *
     * @param {String} params.agenceId        associated agency ID
     * @param {String} params.advertiser      advertiser Name
     * @return  {Promise}                     Resolves when request end
     */
    mapAdv (params) {
      return axios({
        method: 'get',
        url: api.root + '/advertisers',
        params,
        headers
      })
    }
  }
}
