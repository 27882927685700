<template>
  <nav class="facettes-sidebar">
    <Menu
      :active-name="currentActiveMenu"
      :open-names="[]"
      @on-select="name => $router.push(name)"
    >
      <div v-for="(menuK, key) of menu"
           :name="key"
           :key="key"
      >
        <div :name="menuItem.title"

             v-for="(menuItem, keyItem) of menuK"
             :key="keyItem + 'item'"
        >
          <template v-if="menuItem.subMenu">
            <Submenu :name="menuItem.title"
                     style="backgound:#f7f7f7"
            >
              <template slot="title">
                <i :class="menuItem.icon" />
                <span v-if="!isCollapse">{{ menuItem.title }}</span>
              </template>
              <MenuItem :name="dropdownItem.href"
                        v-for="(dropdownItem, dropdownKey) of menuItem.subMenu"
                        :key="dropdownKey + 'dropdown-item'"
              >
                <i :class="dropdownItem.icon" />
                <span v-if="!isCollapse">{{ dropdownItem.title }}</span>
                <Tag v-if="dropdownItem.status && !isCollapse"
                     class="new-tag"
                     :color="dropdownItem.status.color"
                >
                  {{ dropdownItem.status.text }}
                </Tag>
              </MenuItem>
            </Submenu>
          </template>
          <MenuItem :name="menuItem.href"
                    :style="{textAlign: isCollapse ? 'center' : 'left'}"
                    v-else
          >
            <i :class="menuItem.icon" />
            <span v-if="!isCollapse">{{ menuItem.title }}</span>
            <Tag v-if="menuItem.status && !isCollapse"
                 class="new-tag"
                 :color="menuItem.status.color"
            >
              {{ menuItem.status.text }}
            </Tag>
          </MenuItem>
        </div>
        <Divider v-if="key < menuK.length" />
      </div>
    </Menu>
    <!--    <div style="text-align:center;font-size:14px; margin-top:25px;color:#1ca08d">-->
    <!--      <i @click="collapse"-->
    <!--         style=" cursor:pointer; "-->
    <!--         v-if="isCollapse"-->
    <!--         class="fas fa-arrow-right"-->
    <!--      />-->
    <!--      <i @click="collapse"-->
    <!--         style=" cursor:pointer; "-->
    <!--         v-else-->
    <!--         class="fas fa-arrow-left"-->
    <!--      />-->
    <!--    </div>-->
  </nav>
</template>
<script>
import {mapGetters} from 'vuex'
import { selectedDaterange } from '../../store/scope/getters'
export default {
  name: 'SidebarMenu',
  computed: {
    ...mapGetters({
      hasPermissions: 'user/hasPermissions'
    }),
    currentActiveMenu () {
      const route = this.$route.path
      return route
    }
  },
  methods: {
    collapse () {
      this.isCollapse = !this.isCollapse
      this.$emit('collapse', this.isCollapse)

    },
    clickMenu (menuItem) {
    }
  },
  mounted () {
    // compute menu depending to user permissions
    const filteredMenu = this.menu.map(menu => {
      menu.forEach(element => {
        if (element.subMenu) {
          element.subMenu = element.subMenu.filter(subItem => this.hasPermissions(subItem.permissions))
        }
      })
      return menu.filter(link => this.hasPermissions(link.permissions))
    })
    this.menu = filteredMenu

  },
  data () {
    return {
      isCollapse: false,
      filteredMenu: [],
      // Permission viewer and admin will be updated
      menu: [
        [
          {
            title: this.$t('Overview'),
            permissions: ['viewer', 'super-admin', 'admin'],
            href: '/',
            icon: 'fas fa-home',
          },
          {
            href: '/compliance-index',
            permissions: ['viewer', 'super-admin', 'admin'],
            title: this.$t('global.cookie.compliance_index'),
            icon: 'fas fa-clipboard-check'

          },
          {
            permissions: ['viewer', 'super-admin', 'admin', 'publisher-fp', 'cookiepolicy_manager'],
            title: this.$t('global.trackers'),
            icon: 'fas fa-cookie',
            subMenu: [
              {
                title: this.$t('Cookie policy'),
                permissions: ['super-admin', 'cookiepolicy_manager'],
                icon: 'fas fa-shield-alt',
                href: '/trackers/cookie-policy'
              },
              {
                title: this.$t('Active'),
                permissions: ['viewer', 'super-admin', 'admin', 'publisher-fp'],
                icon: 'fas fa-globe-europe',
                href: '/active-trackers'
              },
              {
                title: this.$t('By scenario'),
                permissions: ['viewer', 'super-admin', 'admin', 'publisher-fp'],
                icon: 'fas fa-adjust',
                href: '/trackers'
              },
              {
                href: '/trackers-list',
                permissions: ['super-admin', 'evidence-viewer'],
                title: 'Tracker List',
                icon: 'fas fa-database'
              }
            ]
          },
          {
            title: this.$t('global.vendors'),
            permissions: ['super-admin', 'admin', 'publisher-fp', 'viewer', 'vendorlist_manager'],
            icon: 'fas fa-code-branch',
            subMenu: [
              {
                title: this.$t('Vendors list'),
                permissions: ['super-admin', 'vendorlist_manager'],
                icon: 'fas fa-align-justify',
                href: '/vendors/vendorlist'
              },
              {
                title: this.$t('Active'),
                permissions: ['viewer', 'super-admin', 'admin'],
                icon: 'fas fa-globe-europe',
                href: '/active-vendors'
              },
              {
                title: this.$t('By scenario'),
                permissions: ['viewer', 'super-admin', 'admin'],
                icon: 'fas fa-adjust',
                href: '/vendors'
              },
              {
                title: this.$t('Management'),
                permissions: ['viewer', 'super-admin', 'admin', 'publisher-fp'],
                icon: 'fas fa-wrench',
                href: '/vendors/vendors-management'
              }
            ]
          },
          {
            href: '/notifications',
            permissions: ['viewer', 'super-admin', 'admin'],
            title: this.$t('Weekly reports'),
            icon: 'fas fa-bell',
            // status: {
            //   color: 'blue',
            //   text: 'new'
            // }
          },
          {
            permissions: [],
            title: this.$t('Glossary'),
            icon: 'fa fa-question',
            subMenu: [
              {
                permissions: [],
                title: 'Vendors categories',
                icon: 'fas fa-th-large',
                href: '/vendors/categories'
              },
              {
                permissions: [],
                title: 'Compliance index',
                icon: 'fa fa-clipboard-check',
                href: '/compliance-index/score'
              }

            ]
          },
        ],
        // [

        // ],
        [
          {
            id: 'account',
            href: '/account',
            permissions: ['viewer', 'super-admin', 'admin','vendors-viewer', 'publisher-fp'],
            title: this.$t('global.settings'),
            icon: 'fas fa-user'
          },
          {

            permissions: ['super-admin'],
            title: this.$t('global.users'),
            icon: 'fas fa-users',
            subMenu: [
              {
                title: 'List',
                permissions: ['super-admin'],
                icon: 'fas fa-list',
                href: '/admin/users'

              },
              {
                title: 'Create',
                permissions: ['super-admin'],
                icon: 'fas fa-plus-circle',
                href: '/admin/users/add',
              }
            ]
          },
          {
            title: this.$t('global.vendors_admin'),
            permissions: ['super-admin', 'vendors-viewer'],
            icon: 'fas fa-address-book',
            subMenu: [
              {
                title: 'List',
                permissions: ['super-admin', 'vendors-viewer'],
                icon: 'fas fa-list',
                href: '/admin/vendors',
              },
              {
                title: 'Create',
                permissions: ['super-admin'],
                icon: 'fas fa-plus-circle',
                href: '/admin/add-vendor',
              },
              {
                title: this.$t('Categories'),
                permissions: ['super-admin'],
                new: true,
                icon: 'fas fa-th-large',
                href: '/admin/vendors-category',
              }
            ]
          },
          {
            permissions: ['super-admin'],
            title: this.$t('global.properties'),
            icon: 'fas fa-id-badge',
            subMenu: [
              {
                title: 'List',
                permissions: ['super-admin'],
                icon: 'fas fa-list',
                href: '/admin/properties'

              },
              {
                title: 'Create',
                permissions: ['super-admin'],
                icon: 'fas fa-plus-circle',
                href: '/admin/properties/create',
              }
            ]
          },
          {
            href: '/admin/domains',
            permissions: ['super-admin'],
            title: this.$t('global.domains'),
            icon: 'fas fa-external-link-alt'
          },
        ],
        [
          {
            permissions: ['super-admin', 'video_demo'],
            title: this.$t('global.vast') + ' (disabled)',
            icon: 'fas fa-video',
            subMenu: [
              {
                title: 'Overview',
                permissions: ['super-admin', 'video_demo'],
                icon: 'fas fa-globe-europe',
                href: '/vast',

              },
              {
                title: this.$t('Inventory'),
                href: '/vast-inventory-list',
                permissions: ['super-admin', 'video_demo'],
                icon: 'fa fa-file-video'
              },
              {
                title: 'Player payloads',
                permissions: ['super-admin', 'video_demo'],
                icon: 'fas fa-play',
                href: '/vast-payload-list',
              }
            ]
          },
          // {
          //   href: '/trackers-list',
          //   permissions: ['super-admin'],
          //   title: 'Trackers List (disabled)',
          //   // status: {
          //   //   color: 'red',
          //   //   text: 'Admin'
          //   // } ,
          //   icon: 'fas fa-database'
          // },
        ]
      ]
    }
  }
}
</script>
<style scope lang="scss">
.facettes-sidebar {
  background: #fff;
  height: 100%;
}
</style>
<style lang="scss">
  .facettes-sidebar {
    .new-tag {
    font-size: 10px;
    height: 12px;
    line-height: 0;
    text-transform: none;
    padding: 6px;
    bottom:0;
    top:0;
    position: absolute;
    margin: auto;
    right: 10px;
    }
  .ivu-menu-submenu.ivu-menu-opened{
    background: #f7f7f7;
  }
  .ivu-menu-submenu .ivu-menu-item i:first-of-type {
    &:before{
      font-size:10px;
    }
  }
  li{
    font-size: 12px!important;
    position: relative;
    font-weight: 500;
    color: #707070;
    letter-spacing: .75;
    text-transform: uppercase;
    i:first-of-type {
      position:relative;
      display:inline-block;
      align:center;
      width: 12px;
      height:12px;
      margin-right:18px;
      &:before{
        font-size:13x;
        position: absolute;
        left:50%;
        transform: translate(-50%);
      }
    }
  }
}
</style>
