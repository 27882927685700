import {storageItemsNames} from '../storageItems'
import AuthServices from './../api/auth'

const authApi = AuthServices()
import ApiUserConnector from './../api/user'
import { api as env } from '@/config.js'
import axios from 'axios'

const apiUser = new ApiUserConnector('users')

const proxy = ({fn, call, callback, uid}) => fn('makeCall', {namespace: 'user', promise: call, uid, callback}, {root: true})



export default {

  getUsers: function ({dispatch, rootGetters}, {params, id}) {
    return proxy({fn: dispatch, call: apiUser.get(rootGetters['user/token'], params), id})
  },

  // same as above but using old API for Admin tables.
  getUsersOld: function ({dispatch, rootGetters}, {params, uid = 0}) {
    const limit = params.limit
    const promise = axios({
      method: 'get',
      url: `${env.root}users`,
      params,
      headers: {
        'Authorization': `Bearer ${rootGetters['user/token']}`,
        'Accept': 'application/json',
        'Content-type': 'application/json'
      }
    }).then(res => {
      const {success} = res.data
      const {results, paging} = res.data.data

      return {
        ...res,
        data: {
          data: results,
          pagination: paging,
          success: success
        }
      }
    })

    delete params.limit
    params.perPage = limit
    return proxy({fn: dispatch, call:promise, uid})
  },

  //
  //
  //  TOODO:
  //  The following code should be updated as Above

  /**
   * login
   *
   * login user
   *
   * @param  {Object}  credentials {email, password}
   * @return {Promise}             Resolves when logged in successfully
   */
  authWithCredentials: function (
    { commit, dispatch },
    { credentials, persistant }
  ) {
    commit('SET_LOADING', true, { root: true })
    return authApi
      .login({
        username: credentials.username,
        password: credentials.password
      })
      .then((r) => {
        if (r.data.success) {
          return authApi.auth(r.data.data.token).then(response => {
            commit('SET_LOADING', false)
            if (response.data.success) {
              const user = JSON.parse(localStorage.getItem(storageItemsNames.user))
              if (!!user && (user.id !== response.data.data.user.id)) {
                commit('CLEAN_STORAGE')
              }
              commit('SET_USER_PERSISTANT', persistant)
              commit('SET_TOKEN', {
                token: r.data.data.token,
                persistant: persistant
              })
              commit('SET_USER', {
                user: response.data.data.user,
                persistant: persistant
              })
              return dispatch('fetchUserProperties')
            }
            return response
          })
        }
      })
  },

  /**
   * log out the user
   * @param {*} param0
   * @returns {Promise} resolve api call
   */
  getCurrentUser: function ({ commit, dispatch, state }) {
    const user = JSON.parse(localStorage.getItem(storageItemsNames.user))
    let token = localStorage.getItem(storageItemsNames.token)
    if (state.user && state.user.id && state.token) {
      return true
    }
    if (!user || !token) {
      return dispatch('logout')
    }
    commit('SET_LOADING', true)
    return authApi.auth(token.replace(/['"]+/g, '')).then((r) => {
      if (r.data.success) {
        commit('SET_LOADING', false)
        commit('SET_USER', {user: r.data.data.user})
        commit('SET_TOKEN', {token: token.replace(/['"]+/g, '')})
        return dispatch('fetchUserProperties')
      }
    })
      .catch(e => {
        dispatch('logout')
      })
  },

  /**
   * log out the user
   * @param {*} param0
   * @returns {Promise} resolve api call
   */
  logout: function ({ commit }) {
    commit('SET_LOADING', false)
    commit('LOGOUT_USER', true)
    commit('CLEAN_STORAGE')
    return true
  },

  /**
   * fetch the user's properties
   *
   * @param {*} param0
   * @returns {Promise} resolve the api call
   */
  fetchUserProperties: function ({ commit, dispatch, state }) {
    commit('SET_LOADING', true, { root: true })
    return authApi.fetchUserProperties({}, state.token, {collectEnabledOnly: false}).then((resp) => {
      const { data } = resp
      if (data.success) {
        commit('SET_USER_PROPERTIES', data.widget)
        commit('scope/SET_PROPERTIES', data.widget, { root: true })
        commit('SET_LOADING', false, { root: true })
        dispatch('scope/setStoredProperty', data.widget, {root: true})
        return data.widget
      }
    })
  }
  // getCurrentUser: function(a,b) {
  // },
  // updateCurrentUser: function(a,b) {
  // },
  // signOut: function(a,b) {
  // },
  // getUserProperties: function(a,b) {
  // },
  // onAuthStateChange: function(a,b) {
  // },
  // onIdTokenChange: function(a,b) {
  // },
  // setPersistance: function(a,b) {
  // }
}
