import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      lang: 'user/lang'
    })
  },
  methods: {
    t (key) {
      return this.$t(key, this.lang)
    },
    d (date, format) {
      const m = moment(date)
      return m.isValid() ? m.locale(this.lang).format(format || 'L') : ''
    }
  }
}
