import i18n from "@/i18n/index"

export const WIDGET_NAMES = {
  total: i18n.t("global.cookie_policy.widget_names.total"),
  qualified: i18n.t("global.cookie_policy.widget_names.qualified"),
  unqualified: i18n.t("global.cookie_policy.widget_names.unqualified"),
  exempted: i18n.t("global.cookie_policy.widget_names.exempted"),
}

export const WIDGET_ALERT_NAMES = {
  total: i18n.t("global.cookie_policy.widget_alert.total"),
  qualified: i18n.t("global.cookie_policy.widget_alert.qualified"),
  unqualified: i18n.t("global.cookie_policy.widget_alert.unqualified"),
  exempted: i18n.t("global.cookie_policy.widget_alert.exempted"),
}

export const WIDGET_KEYS = {
  total: "total",
  qualified: "qualified",
  unqualified: "unqualified",
  exempted: "exempted",
}

export const COOKIE_POLICY_TOP_WIDGET = [
  {
    name: WIDGET_NAMES.total,
    key: WIDGET_KEYS.total,
  },
  {
    name: WIDGET_NAMES.qualified,
    key: WIDGET_KEYS.qualified,
  },
  {
    name: WIDGET_NAMES.unqualified,
    key: WIDGET_KEYS.unqualified,
    showWarning: true,
  },
  {
    name: WIDGET_NAMES.exempted,
    key: WIDGET_KEYS.exempted,
  },
]

//@TODO: add this to i18n.
export const TEXTS = {
  ADD_COOKIE: "ADD COOKIE",
  EXPORT_QUALIFIED: "Export the Cookie Policy project",
  AUTO_QUALIFY_TCF: "Qualify TCF vendors trackers and add them to the to Cookie Policy project",
  PROJECTED_COOKIE_POLICY: "Projected Cookie Policy",
  TABLE_DESCRIPTION:
    "The table below displays all trackers that were detected on your website for the selected period.",
}

export const EXEMPTED_RADIO_LIST = [
  {
    id: 1,
    key: "user_choice_storage",
    title: "USER CHOICE STORAGE",
    description:
      "trackers keeping the choice expressed by the user on the deposit of trackers or the will of the latter to not express a choice",
  },
  {
    id:2,
    key: "authentification",
    title: "AUTHENTIFICATION",
    description: "trackers intended to authentification to a service",
  },
  {
    id:3,
    key: "shopping_basket_storage",
    title: "SHOPPING BASKET STORAGE",
    description:
      "trackers intended to store the content of a shopping basket on a commercial site",
  },
  {
    id: 4,
    key: "personalization",
    title: "PERSONALIZATION",
    description:
      "personalization trackers of the user interface (for example, for the choice of the language or the presentation of a service), when such a personalisation represents an intrinsic part that is expected by the user of the service ",
  },
  {
    id: 5,
    key: "load_balancing",
    title: "LOAD BALANCING",
    description:
      "trackers that make a balancing of the volume of equipments contributing to a communication service ",
  },
  {
    id: 6,
    key: "access_measurement",
    title: "AUDIENCE MEASUREMENT",
    description:
      "trackers measuring the audience, in the specific case of the Article 5 of the guidelines about the cookies and other trackers",
    hasSubKeys: true,
  },
]
