// import axios from 'axios'
// import { api as apiConf } from './../../config'
//
// /**
//  * PropertiesApi
//  *
//  * Build an instance of PropertiesApi
//  *
//  * @return {Object}     PropertiesApi instance with method to video api
//  */
// export default function api () {
//   const headers = {
//     Accept: 'application/json',
//     'Content-type': 'application/json'
//   }
//
//   return {
//     /**
//      * ask properties api
//      *
//      * @param   {Object}    params            The api call parameters
//      * @return  {Promise}                     Resolves when request end
//      */
//     get (token, params = {}) {
//       headers['Authorization'] = 'Bearer ' + token
//       return axios({
//         method: 'get',
//         url: apiConf.root + 'api/properties',
//         params,
//         headers
//       })
//     },
//
//     /**
//      * post api property
//      *
//      * @param   {Object}    data            The api call parameters
//      * @return  {Promise}                     Resolves when request end
//      */
//     put (token, data = {}, id) {
//       headers['Authorization'] = 'Bearer ' + token
//       return axios({
//         method: 'put',
//         url: apiConf.root + 'api/properties/' + id,
//         data,
//         headers
//       })
//     },
//
//     /**
//      * post api property
//      *
//      * @param   {Object}    data            The api call parameters
//      * @return  {Promise}                     Resolves when request end
//      */
//     post (token, data = {}) {
//       headers['Authorization'] = 'Bearer ' + token
//       return axios({
//         method: 'post',
//         url: apiConf.root + 'api/properties',
//         data,
//         headers
//       })
//     },
//
//     /**
//      * get property by id
//      *
//      * @param   {Number}    id            The api call parameters
//      * @return  {Promise}                     Resolves when request end
//      */
//     view (token, id) {
//       headers['Authorization'] = 'Bearer ' + token
//       return axios({
//         method: 'get',
//         url: apiConf.root + 'api/properties/' + id,
//         headers
//       })
//     }
//   }
// }

import ApiConnector from './index'

class ApiPropertyConnector extends ApiConnector {
  bulkCreateProperties (token, data = {}) {
    return this._axios({token, data, method: 'post', specialUrl: 'api/properties/bulk_create'})
  }
  togglePropertyForUser(token, data = {}) {
    return this._axios({token, data, method: 'post', specialUrl: 'api/properties/togglePropertyForUser'})
  }
}



export default ApiPropertyConnector
