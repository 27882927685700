export default {
  GET_PROPERTY_PENDING (state, payload) {
    state.pending = payload.state
  },
  GET_PROPERTY_ERROR (state, payload) {
    state.pending = payload.state
  },
  GET_PROPERTY_SUCCESS (state, payload) {
    state.success = payload.state
  }
}
