<template>
  <div
    id="app"
    v-if="!isLoading"
  >
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    isLoading () {
      return this.$store.getters['user/loading']
    }
  }
}
</script>

<style lang="scss">
@import './assets/styles/app';

html, body, #app {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
</style>
