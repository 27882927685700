import ApiConnector from './index'

class ApiNotificationConnector extends ApiConnector {
  edit (token, params) {
    return this._axios({token, id: params.id, method: 'post', data: params.body, specialUrl: 'api/notifications/edit'})
  }
  getTypes (token) {
    return this._axios({token, method: 'get', specialUrl: 'api/notificationtypes'})
  }
  getNotificationEmails (token, params) {
    return this._axios({token, method: 'get', specialUrl: 'api/NotificationEmails', params})
  }
  getRegisteredNotifs (token, params) {
    return this._axios({token, method: 'get', specialUrl: 'api/notificationtypesUsers', params})
  }
  registerNotifs (token, data) {
    return this._axios({token, method: 'put', specialUrl: 'api/notificationtypesUsers', data})
  }
  getNotificationDailies (token, params) {
    return this._axios({token, method: 'get', specialUrl: 'api/notificationDailies', params})
  }
  getInformationMessages (token, params) {
    return this._axios({token, method: 'get', specialUrl: 'api/informationMessages', params})
  }
}
export default ApiNotificationConnector

