export default {
  setOptions (state, options) {
    state.options = options
  },
  GET_PROPERTIES_PENDING (state, status) {
    state.pending = status
  },
  GET_PROPERTIES_ERROR (state, status) {
    state.error = status
  },
  GET_PROPERTIES_SUCCESS (state, status) {
    state.success = status
  }
}
