export const storageItemsNames = {
  lastMessageOpened: 'fa7-last-message-opened',
  selectedProperty: 'fa7-selectedProperty',
  selectedDaterange: 'fa7-selectedDaterange',
  properties: 'fa7-properties',
  saveSelectedDaterange: 'fa7-saveSelectedDaterange',
  user: 'fa7-user',
  token: 'fa7-token',
  userPersistent: 'fa7-user-persistent'
}
