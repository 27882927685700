import {storageItemsNames} from '../storageItems'

export const setFocus = ({commit}, payload) => commit('SET_FOCUS', payload)

export const setSaveSelectedDaterange = ({commit, getters}, payload) => commit('SET_SAVE_SELECTED_DATERANGE', payload)

export const setSelectedDaterange = ({commit, getters}, payload) => commit('SET_SELECTED_DATERANGE', {dateRange: payload, getters})

export const setProperties = ({commit}, payload) => commit('SET_PROPERTIES', payload)

export const setSelectedProperty = ({commit, getters}, payload) => {
  commit('SET_SELECTED_PROPERTY', {selectedProperty: payload, getters})
}

export const setStoredProperty = ({commit, getters}, payload) => {
  let storedProperty = localStorage.getItem(storageItemsNames.selectedProperty)
  if (storedProperty && storedProperty !== 'undefined') {
    storedProperty = JSON.parse(storedProperty)

    if ((storedProperty = Object.values(payload).find(property => property.id === storedProperty.id))) {
      commit('SET_SELECTED_PROPERTY', {selectedProperty: storedProperty, getters})
    }
  }
}

export const resetScope = ({commit}) => commit('RESET_SCOPE')
