import ApiPropertyConnector from '../api/properties'

// Create restful api/property object
const apiProperty = new ApiPropertyConnector('properties')

// Generic dispatch('makeCall') with the module namespace for mutations
const proxy = ({fn, call, callback, uid}) => fn('makeCall', {namespace: 'property', promise: call, uid, callback: callback}, {root: true})

export default {
  bulkCreateProperties: function ({dispatch, rootGetters}, data) {
    return proxy({fn: dispatch, call: apiProperty.bulkCreateProperties(rootGetters['user/token'], data)})
  },
  togglePropertyForUser: function ({dispatch, rootGetters}, data) {
    return proxy({fn: dispatch, call: apiProperty.togglePropertyForUser(rootGetters['user/token'], data)})
  },
  getProperties: function ({dispatch, rootGetters}, {params, uid = 0}) {
    if (!params) params = arguments[1]
    return proxy({fn: dispatch, call: apiProperty.get(rootGetters['user/token'], params), uid})
  },

  getPropertyById: function ({dispatch, rootGetters}, id) {
    return proxy({fn: dispatch, call: apiProperty.view(rootGetters['user/token'], id)})
  },

  createProperty: function ({dispatch, rootGetters}, vendor) {
    return proxy({fn: dispatch, call: apiProperty.post(rootGetters['user/token'], vendor)})
  },

  editPropertyById: function ({dispatch, rootGetters}, { data, id }) {
    return proxy({fn: dispatch, call: apiProperty.put(rootGetters['user/token'], id.toString(), data)})
  }
}
