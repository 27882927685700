var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('vue-slider', {
    attrs: {
      "data": _vm.availableRangeValues,
      "marks": true,
      "enable-cross": false,
      "tooltip-placement": ['top', 'top'],
      "min-range": 0,
      "max-range": 31
    },
    on: {
      "drag-end": function dragEnd() {
        return _vm.selectedRangeValues = _vm.selectingRangeValues;
      }
    },
    model: {
      value: _vm.selectingRangeValues,
      callback: function callback($$v) {
        _vm.selectingRangeValues = $$v;
      },
      expression: "selectingRangeValues"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }