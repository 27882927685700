<template>
  <div class="property-seltc2">
    <Poptip placement="bottom-start"
            ref="propertyPop"
            v-model="isPropertypoptipOpen"
            style="margin-right:16px"
    >
      <Button class="property-popper"
              size="large"
              style="text-align:left"
              :style="{ width: selectedProperty.id?'218px':'218px' }"
      >
        <i class="fas fa-layer-group" /><span style="margin:0 5px">{{ truncate(selectedProperty.displayName ? selectedProperty.displayName : 'Website properties', 20) }}</span><span style="position: absolute;right: 0px"><i class="fas fa-sort-down" /></span>
      </Button>
      <div slot="content">
        <Row type="flex"
             style="max-width:420px"
        >
          <Col span="8">
            <Row style="padding-top:5px">
              <Checkbox v-model="propertiesFilter.onlyEnableProperties"
                        v-if="properties.length > 6 || userHasRole('super-admin')"
              >
                <i style="margin-left:5px; font-size:12px"
                   class="fas fa-check-square"
                />                <span style="margin-left:5px">Show only enabled properties</span>
              </Checkbox>
              <!--              <Checkbox v-model="propertiesFilter.onlyDisabledProperties"-->
              <!--                        v-if="properties.length > 6"-->
              <!--              >-->
              <!--                <i style="margin-left:5px; font-size:12px"-->
              <!--                   class="fas fa-times"-->
              <!--                />                <span style="margin-left:7px">Show only disabled properties</span>-->
              <!--              </Checkbox>-->
              <Checkbox v-model="propertiesFilter.onlyEnableCollectsProperties"
                        v-if="properties.length > 6"
              >
                <i style="margin-left:5px; font-size:12px"
                   class="fas fa-layer-group"
                />
                <span style="margin-left:5px">Show only collection enabled properties</span>
              </Checkbox>
              <div style="display:flex; justify-content: space-between">
                <Checkbox v-model="propertiesFilter.freshData"
                          style="margin-right:20px"
                >
                  <i style="margin-left:5px; font-size:12px; color:#81c327"
                     class="fas fa-layer-group"
                  />                <span style="margin-left:7px">Fresh Data</span>
                </Checkbox>
                <Checkbox v-model="propertiesFilter.staleData"
                          style="margin-right:20px"
                >
                  <i style="margin-left:5px; font-size:12px; color: #ffbd41"
                     class="fas fa-layer-group"
                  />                <span style="margin-left:7px">Stale data</span>
                </Checkbox>
                <Checkbox v-model="propertiesFilter.noData"
                          style="margin-right:20px"
                >
                  <i style="margin-left:5px; font-size:12px; color: #d55d78"
                     class="fas fa-layer-group"
                  />                <span style="margin-left:7px">No data</span>
                </Checkbox>
              </div>
            </Row>
          </Col>
        </Row>
        <br><span style="font-size:10px; display:block">(count : {{ filteredPropertyList.length }})</span>

        <span v-if="selectedProperty.displayName"
              @click="selectProperty({})"
        >
          <Divider />
          Currently selected : <strong style="color:#1CA08D; margin-left:5px">{{ selectedProperty ? selectedProperty.displayName : '' }}</strong>
        </span>
        <Divider />

        <Input prefix="ios-search"
               v-if="properties.length > 6 || userHasRole('super-admin')"
               ref="inputProp"
               :autofocus="true"
               class="property-select-input"
               @on-change="searchPropertiesByName"
               v-model="propertyNameFilter"
               style="width:100%; margin-bottom:10px"
               :suffix="'hello'"
               placeholder="Search"
        />

        <Row style="max-height: 200px; width:420px; overflow:auto; overflow-x:hidden">
          <div style="width:100%"
               class="property-item"

               v-for="item in filteredPropertyList"
               :key="item.displayName + item.isRemoteSearchResult"
          >
            <span @click="selectProperty(item)"
                  style="width:100%;display:inline-block"
            >
              <i v-if="item.isRemoteSearchResult"
                 style="margin-left:5px; font-size:12px"
                 :style="{'color': whichDataStatus(item)}"
                 class="fas fa-layer-group"
              />
              <i v-else
                 style="margin-left:5px; font-size:12px"
                 :style="{'color': whichDataStatus(item)}"
                 class="fas fa-layer-group"
              />
              {{ item.displayName }}
              <span v-if="userHasRole('super-admin')"
                    @click.stop="togglePropertyForUser(item)"
                    style="display:inline-block;width:20px;text-align:center;margin-right:5px; font-size:12px;float:right;"
              >
                <i v-if="item.isRemoteSearchResult"
                   :style="{'color': 'green'}"
                   class="fas fa-plus"
                />
                <i v-else
                   :style="{'color': 'orange'}"
                   class="fas fa-minus"
                />
              </span>
            </span>
          </div>
        </Row>
      </div>
    </Poptip>
    <Poptip placement="bottom-start"
            v-model="isDatepickerpoptipOpen"
            ref="datepickerPop"
            class="poptip-container"
    >
      <Button size="large"
              style="width:218px;text-align:left;"
              :disabled="!selectedProperty.id"
      >
        <span>

          <i class="fas fa-calendar-alt" /> <span style="margin:0 5px"> {{ last14CollectedDays }} </span>
          <span style="position:absolute; right:15px"><i class="fas fa-sort-down" /></span>
        </span>
      </Button>
      <Row slot="content"
           type="flex"
      >
        <template>
          <Col span="24"
               style="padding-left:16px; height: 100%;"
          >
            <div style="border-bottom: 1px solid #ebebeb">
              <DatePicker type="daterange"
                          v-model="datepicker"
                          :options="disabledDates"
                          @on-change="datepickerHandleChange"
                          placement="bottom-end"
                          placeholder="Select date"
                          style="width: 240px"
              />
            </div>
          </Col>
          <Col span="20"
               offset="2"
          >
            <div style="display:flex; justify-content: space-between;">
              <div class="chips chips--block-item chips--light"
                   @click="setDefaultDate(7)"
              >
                Last 7 days
              </div>
              <div class="chips chips--block-item chips--light"
                   @click="setDefaultDate(14)"
              >
                Last 14 days
              </div>
              <div class="chips chips--block-item chips--light"
                   @click="setDefaultDate(30)"
              >
                Last 30 days
              </div>
              <div class="chips chips--block-item chips--light"
                   @click="setDefaultDate(90)"
              >
                Last 90 days
              </div>
              <!--              <br>-->
              <!--              <br>-->
              <!--              <span style="text-align:center;margin-top:auto;font-size:10px">-->
              <!--                * from the lastest collect-->
              <!--              </span>-->
            </div>
          </Col>
          <Col span="24">
            <br>
            <Alert show-icon
                   type="warning"
                   v-if="!selectedProperty.id"
            >
              You need to select a property first
            </Alert>
            <div v-else
                 style="float:right; margin-bottom:15px;margin-right:35px"
            >
              <Button
                style="margin-right:15px"
                @click="isDatepickerpoptipOpen = false"
              >
                Cancel
              </Button>
              <Button type="primary"
                      :disabled="selectedProperty.firstIngest === null || selectedProperty.lastIngest === null"
                      @click="applyDatepickerDate"
              >
                Set date
              </Button>
              <br>
            </div>
          </Col>
        </template>
      </Row>
    </Poptip>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import moment from 'moment'
import { truncate } from './../../helpers/strings'
export default {
  data () {
    return {
      moment: moment,
      truncate: truncate,
      isPropertypoptipOpen: false,
      isDatepickerpoptipOpen: false,
      datepicker: [],
      propertyNameFilter: '',
      pickedDaterange: [],
      propertylist: [],
      propertiesFilter: {
        // onlyDisabledProperties: false,
        onlyEnableProperties: true,
        onlyEnableCollectsProperties: false,
        noData: true,
        staleData: true,
        freshData: true
      }
    }
  },
  watch : {
    isPropertypoptipOpen: {
      handler: function(val, old) {
        if (val) {
          document.getElementsByTagName('body')[0].style.overflow = 'hidden'
        } else {
          document.getElementsByTagName('body')[0].style.overflow = 'inherit'
        }
      }
    }
  },
  mounted () {
    this.propertylist = this.properties
    this.setDefaultDate()
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      userHasRole: 'user/hasRole',
      properties: 'scope/properties',
      selectedProperty: 'scope/selectedProperty',
      selectedDaterange: 'scope/selectedDaterange'
    }),

    last14CollectedDays () {
      if (!this.selectedProperty.id) return 'Wait for a property'
      if (this.selectedProperty.firstIngest === null || this.selectedProperty.lastIngest === null) return 'No data available'
      if (this.selectedDaterange[0] && this.selectedDaterange[1]) return this.selectedDaterange[0] + '  -  ' + this.selectedDaterange[1]
      return moment(this.selectedProperty.lastIngest).subtract(14, 'days').format('YYYY-MM-DD') + '  -  ' + moment(this.selectedProperty.lastIngest).format('YYYY-MM-DD')
    },
    disabledDates (){
      return {disabledDate: this.isSelectableDate}
    },
    filteredPropertyList () {
      let filtered = JSON.parse(JSON.stringify(this.propertylist))
      if (this.propertiesFilter.onlyEnableProperties) {
        filtered = filtered.filter(elm => elm.isEnabled === 1)
      }
      // if (this.propertiesFilter.onlyDisabledProperties) {
      //   filtered = filtered.filter(elm => elm.isEnabled === 0)
      // }
      if (this.propertiesFilter.onlyEnableCollectsProperties) {
        filtered = filtered.filter(elm => elm.isCollectEnabled === 1)
      }
      if (!this.propertiesFilter.noData) {
        filtered = filtered.filter(elm => elm.firstIngest !== null)
      }
      if (!this.propertiesFilter.freshData) {
        filtered = filtered.filter(elm => elm.lastIngest && moment().format('YYYY-MM-DD') !== moment(elm.lastIngest).format('YYYY-MM-DD') || elm.firstIngest === null)
      }
      if (!this.propertiesFilter.staleData) {
        filtered = filtered.filter(elm => moment().format('YYYY-MM-DD') === moment(elm.lastIngest).format('YYYY-MM-DD') || elm.firstIngest === null)
      }
      return filtered
    },
  },
  methods: {
    ...mapActions({
      fetchUserProperties: 'user/fetchUserProperties',
      propertiesTogglePropertyForUser: 'property/togglePropertyForUser',
      propertiesGetProperties: 'property/getProperties',
      setProperty: 'scope/setSelectedProperty',
      setSelectedDaterange: 'scope/setSelectedDaterange'
    }),
    setDefaultDate (devaultValue = 14) {
      if (this.selectedProperty.id) {
        if (this.selectedProperty.lastIngest && this.selectedProperty.firstIngest) {
          let start = moment(this.selectedProperty.firstIngest)
          let end = moment(this.selectedProperty.lastIngest)
          const nbDays = end.diff(start, 'days')
          let range = []
          if (nbDays >= devaultValue ) {
            range = [moment(this.selectedProperty.lastIngest).subtract(devaultValue, 'days').format('YYYY-MM-DD'), moment(this.selectedProperty.lastIngest).format('YYYY-MM-DD')]
          } else {
            range = [moment(this.selectedProperty.firstIngest).format('YYYY-MM-DD'), moment(this.selectedProperty.lastIngest).format('YYYY-MM-DD')]
          }
          this.datepicker = range
          this.pickedDaterange = range
          this.applyDatepickerDate()
          Array.from(document.getElementsByClassName('shortcut')).forEach(node=>{
            node.classList.remove('hover-mode')
          })
          if (devaultValue === 14) document.getElementsByClassName('chips')[1].classList.add('hover-mode')
          if (devaultValue < 14) document.getElementsByClassName('chips')[0].classList.add('hover-mode')
          if (devaultValue === 30) document.getElementsByClassName('chips')[2].classList.add('hover-mode')
          if (devaultValue === 60) document.getElementsByClassName('chips')[3].classList.add('hover-mode')



        }
      }
    },
    isSelectableDate (date) {
      if (moment(this.selectedProperty.firstIngest).isSame(this.selectedProperty.lastIngest)) {
        return !moment(date).isSame(this.selectedProperty.firstIngest)
      }
      return !moment(date).isBetween(this.selectedProperty.firstIngest, this.selectedProperty.lastIngest, 'day', '[]')
    },
    applyDatepickerDate () {
      Array.from(document.getElementsByClassName('shortcut')).forEach(node=>{
        node.classList.remove('hover-mode')
      })
      this.setSelectedDaterange(this.pickedDaterange)
      this.isDatepickerpoptipOpen = false
    },
    whichDataStatus (property) {
      if (property.firstIngest === null || property.lastIngest === null) return '#d55d78'
      if (moment().format('YYYY-MM-DD') === moment(property.lastIngest).format('YYYY-MM-DD')) return '#81c327'
      if (property.lastIngest && moment().format('YYYY-MM-DD') !== moment(property.lastIngest).format('YYYY-MM-DD')) return '#ffbd41'
    },
    datepickerHandleChange (val) {
      this.pickedDaterange = val
      Array.from(document.getElementsByClassName('shortcut')).forEach(node=>{
        node.classList.remove('hover-mode')
      })
    },
    togglePropertyForUser(property){
      this.propertyNameFilter = ''
      this.propertiesTogglePropertyForUser({property_id : property.id})
        .then(() => this.fetchUserProperties())
        .then(() => {
          this.propertylist = this.properties.filter(elm => elm.displayName.toLowerCase().includes(this.propertyNameFilter.toLowerCase()))
        })
    },
    searchPropertiesByName (name) {
      this.propertylist = this.properties
      if (this.propertyNameFilter.length) {
        if (this.userHasRole('super-admin')){
          this.propertiesGetProperties({'q' : this.propertyNameFilter})
            .then(resp => {
              let remotePropertiesResults = []
              for(const entry of resp.data){
                remotePropertiesResults.push(
                  {
                    customer : entry.customer_id,
                    description: entry.description,
                    didomiId:entry.didomiId,
                    displayName:entry.displayName,
                    firstIngest:entry.first_ingest_date,
                    id:entry.id,
                    industry_id:entry.industry_id,
                    isCollectEnabled:entry.isCollectEnabled,
                    isEnabled:entry.isEnabled,
                    lastIngest:entry.last_ingest_date,
                    propertyId:entry.propertyId,
                    uniqId:entry.uniqId,
                    website:entry.website,
                    isRemoteSearchResult:1
                  }
                )
              }
              // console.log(resp)
              this.propertylist = [...this.properties, ...remotePropertiesResults].filter(elm => elm.displayName.toLowerCase().includes(this.propertyNameFilter.toLowerCase()))
            })
        }else{
          this.propertylist = this.properties.filter(elm => elm.displayName.toLowerCase().includes(this.propertyNameFilter.toLowerCase()))
        }
      }
    },
    selectProperty(property) {
      this.isPropertypoptipOpen = false
      this.propertyNameFilter = ''
      this.searchPropertiesByName()
      this.setProperty(property)
      this.setDefaultDate()
    }
  }
}
</script>
<style lang="scss">

@media only screen and (max-width: 1200px) {
  .property-seltc2{
      display: flex;
  }
}
  .property-seltc2{

    .ivu-poptip-body-content {
      overflow: inherit!important;
    }
    .property-popper > span {
      width:100%!important;
      position: relative;
    }
    .hover-mode {
      background: rgba(107, 170, 216, 0.19);
    }
    .property-item {
      padding-left: 5px;
      padding-top:6px;
      padding-bottom: 6px;
      cursor: pointer;
      &:hover {
        background: #ebebeb6b;
      }
    }
    .ivu-divider-horizontal {
      margin: 14px 0;
    }
    .poptip-container {
      .shortcut {
      text-align: center;
      vertical-align: middle;
      padding: 5px 0;
      cursor: pointer;
      &:hover {
        background: #ebebeb6b;
      }
    }
    .ivu-poptip-body {
      padding:0!important;
    }
    .ivu-poptip-popper{
      width: calc(100% + 32px)!important;
      max-width: 480px!important;
    }
    .ivu-date-picker-rel {
      display: none!important;
    }
    .ivu-select-dropdown {
      position: inherit!important;
      display: block!important;
    }
    .ivu-date-picker-next-btn-arrow-double, .ivu-date-picker-prev-btn-arrow-double  {
      display: none!important;
    }
      .ivu-date-picker-cells{
        span.ivu-date-picker-cells-cell-selected em{
          border-radius : 25% 0 0 25%;
        }
        span.ivu-date-picker-cells-cell-selected ~ span.ivu-date-picker-cells-cell-selected em{
          border-radius : 0 25% 25% 0;
        }
        //&.ivu-date-picker-cells-cell-selected:first-of-type em{
        //  border-radius : 25% 0 0 25%;
        //}
      }
    }


  }
</style>
