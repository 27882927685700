export default {
  GET_VIDEO_PENDING (state, payload) {
    state.pending = payload.state
  },
  GET_VIDEO_ERROR (state, status) {
    state.error = status
  },
      
  GET_VIDEO_SUCCESS (state, payload) {
    // allow only 15mbit on vendor state
    // if (_getMemory(state)/1000000 < 15 ) 
    state[payload.field] = payload.data
    state.success = payload.status
  },
  DISPLAY_CATEGORY_HOVER (state, value) {
    state.displayCategoryHelper = value
  }
}
