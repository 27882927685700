import {use} from 'view-design/src/locale'

export default {
  userProperties: (state) => state.properties,
  lang: state => state.lang,
  token: state => state.token,
  user: state => state.user,
  loggedIn: state => state.loggedIn,
  yoyo: state => 25,
  redirectAfterLogin: state => state.redirectAfterLogin,
  userPersistant: state => state.user_persistant,
  loading: state => state.loading,
  hasRole: state => (askedRole) => {
    const {user} = state
    // User exists?
    if (!user) {
      return false
    }
    const rolesMap = state.user.roles
    const t = !!rolesMap.find(role => role.alias === askedRole)
    // Is super admin ?
    return t
  },

  /**
   * Instead of above it check if the user has as role a list of asked permissions in array
   * If there is no asked permissions it return a valid access
   * 
   * @param {Array} permissions  list of permission to check
   * @returns {Boolean} true if the user has one of asked permissions
   */
  hasPermissions: state => (permissions) => {
    if (typeof permissions === "string") permissions = [permissions]
    if (permissions.length === 0) return true
    const {user} = state
    // User exists?
    if (!user) {
      return false
    }
    const rolesMap = state.user.roles
    let authorisation = permissions.filter(perm => {
      return rolesMap.find(role => role.alias === perm)
    })
    
    // Is super admin ?
    return !!authorisation.length
  }
}
