import ApiVendorConnector from './../api/vendor'

// Create restful api/vendor object
const apiVendor = new ApiVendorConnector('vendors')

// Generic dispatch('makeCall') with the module namespace for mutations
const proxy = ({fn, call, callback, uid}) => fn('makeCall', {namespace: 'vendor', promise: call, uid, callback: callback}, {root: true})

export default {

  exportCsv: function ({dispatch, rootGetters}, params) {
    return apiVendor.export(rootGetters['user/token'], params)
  },

  getVendors: function ({dispatch, rootGetters}, {params, uid = 0}) {
    // define parameters if function was not call with object parameters
    if (!params) params = arguments[1]
    return proxy({fn: dispatch, call: apiVendor.get(rootGetters['user/token'], params), uid})
  },
  getVendorTCFDeclaration: function ({dispatch, rootGetters}, {params, uid =0}) {
    if (!params) params = arguments[1]
    return proxy({fn: dispatch, call: apiVendor.getVendorTCFDeclaration(rootGetters['user/token'], params), uid})
  },
  getVendorObservedVendorList: function ({dispatch, rootGetters}, {params, uid = 0}) {
    // define parameters if function was not call with object parameters
    if (!params) params = arguments[1]
    return proxy({fn: dispatch, call: apiVendor.getVendorObservedVendorList(rootGetters['user/token'], params), uid})
  },
  getVendorDeclaredVendors: function ({dispatch, rootGetters}, {params, uid = 0}) {
    // define parameters if function was not call with object parameters
    if (!params) params = arguments[1]
    return proxy({fn: dispatch, call: apiVendor.getVendorDeclaredVendors(rootGetters['user/token'], params), uid})
  },
  getVendorlist: function ({dispatch, rootGetters}, {params, uid = 0}) {
    // define parameters if function was not call with object parameters
    if (!params) params = arguments[1]
    return proxy({fn: dispatch, call: apiVendor.getVendorlist(rootGetters['user/token'], params), uid})
  },
  postVendorToVendorlist: function ({dispatch, rootGetters}, {data, uid = 0}) {
    return proxy({fn: dispatch, call: apiVendor.postVendorToVendorlist(rootGetters['user/token'], data), uid})
  },
  removeVendorFromVendorlist: function ({dispatch, rootGetters}, {data, uid = 0}) {
    return proxy({fn: dispatch, call: apiVendor.removeVendorFromVendorlist(rootGetters['user/token'], data), uid})
  },
  getVendorcategories: function ({dispatch, rootGetters}, {params = {limit: 30}, uid = 0}) {
    // define parameters if function was not call with object parameters
    if (!params) params = arguments[1]
    return proxy({fn: dispatch, call: apiVendor.getVendorcategories(rootGetters['user/token'], params), uid})
  },
  editVendorcategories: function ({dispatch, rootGetters}, {data, id}) {
    return proxy({fn: dispatch, call: apiVendor.editVendorcategory(rootGetters['user/token'], data, id)})
  },
  getVendorById: function ({dispatch, rootGetters}, id) {
    return proxy({fn: dispatch, call: apiVendor.view(rootGetters['user/token'], id)})
  },

  createVendor: function ({dispatch, rootGetters}, vendor) {
    return proxy({fn: dispatch, call: apiVendor.post(rootGetters['user/token'], vendor)})
  },
  editVendorById: function ({dispatch, rootGetters}, { id, vendor }) {
    return proxy({fn: dispatch, call: apiVendor.put(rootGetters['user/token'], id.toString(), vendor)})
  },
  getAllOptions: function ({ dispatch, getters, rootGetters }) {
    const apiCalls = getters.options.map(option => apiVendor.getOptions(rootGetters['user/token'], option))
    const cb = res => {
      let results = {}
      getters.options.forEach((option, id) => {
        if (res[id].data.success) {
          results[option] = res[id].data.data
        }
      })
      return results
    }
    return proxy({fn: dispatch, call: Promise.all(apiCalls), callback: cb})
  },
  getOptions: function ({ dispatch, getters, rootGetters }, name) {
    name = name.replace(/^\//, '').replace(/\/$/, '')

    if (!getters.options.find(n => n === name)) {
      return Promise.reject(new Error('Option name doesn\'t exist'))
    }
    return proxy({fn: dispatch, call: apiVendor.getOptions(rootGetters['user/token'], name)})
  },
  addVendorAliases: function ({ dispatch, rootGetters }, { aliasNames, vendorId }) {
    aliasNames = aliasNames.map(name => name.replace(/^[ ]+/, '').replace(/[ ]+$/, '').toUpperCase())
    const apiCalls = aliasNames.map(name => apiVendor.postVendorAliases(
      rootGetters['user/token'], {
        vendor_id: vendorId,
        name: name,
        origin: 'Agnostik'
      }
    ))
    return proxy({fn: dispatch, call: Promise.all(apiCalls)})
  },

  getVendorAliases: function ({dispatch, rootGetters}, vendorId) {
    return proxy({fn: dispatch, call: apiVendor.getVendorAliases(rootGetters['user/token'], vendorId)})
  },

  getVendorDomains: function ({state, dispatch, rootGetters}, {params, uid}) {
    if (!params) params = arguments[1]
    return proxy({fn: dispatch, call: apiVendor.getVendorDomains(rootGetters['user/token'], params), uid})
  },

  deleteVendorAliases: function ({ dispatch, rootGetters }, aliasesIds) {
    const apiCalls = aliasesIds.map(id => apiVendor.deleteVendorAliases(rootGetters['user/token'], id))
    return proxy({fn: dispatch, call: Promise.all(apiCalls), callback: () => { return {success: true} }})
  }
}
