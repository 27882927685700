<template>
  <div>
    <vue-slider @drag-end="()=>selectedRangeValues = selectingRangeValues"
                v-model="selectingRangeValues"
                :data="availableRangeValues"
                :marks="true"
                :enable-cross="false"
                :tooltip-placement="['top', 'top']"
                :min-range="0"
                :max-range="31"
    />
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import moment from 'moment'
import { truncate } from './../../helpers/strings'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  components : {
    VueSlider
  },
  data () {
    return {
      moment: moment,
      availableRangeValues : [],
      selectingRangeValues : [],
      // selectedRangeValues : []
    }
  },
  watch : {
    selectedProperty: {
      handler: function (old, val) {
        this.setDateranges()
      }
    },
    selectedDaterange: {
      handler: function (old, val) {
        this.setDateranges()
      }
    }
  },
  mounted () {
    // this.propertylist = this.properties
    // this.setDefaultDate()
    this.setDateranges()

  },
  computed: {
    ...mapGetters({
      properties: 'scope/properties',
      selectedProperty: 'scope/selectedProperty',
      selectedRangeValues: 'scope/selectedDaterange',
      qs: 'scope/qs',
    }),

  },
  methods: {
    ...mapActions({
      setProperty: 'scope/setSelectedProperty',
      setSelectedDaterange: 'scope/setSelectedDaterange'
    }),
    setDateranges () {
      let daterange = [this.selectedProperty.firstIngest, this.selectedProperty.lastIngest]

      this.availableRangeValues = []
      const availableRangeStartDate = moment(daterange[0])
      const availableRangeEndDate = moment(daterange[1])

      let availableRangeIterator = moment(daterange[0])
      while (availableRangeIterator.isBefore(availableRangeEndDate)){
        this.availableRangeValues.push(availableRangeIterator.format('YYYY-MM-DD'))
        availableRangeIterator.add(1, 'day')
      }
      this.availableRangeValues.push(availableRangeEndDate.format('YYYY-MM-DD'))

      this.selectingRangeValues = [availableRangeStartDate.format('YYYY-MM-DD'), availableRangeStartDate.format('YYYY-MM-DD')]
      this.selectedRangeValues = [availableRangeStartDate.format('YYYY-MM-DD'), availableRangeStartDate.format('YYYY-MM-DD')]
      //debugger
      this.selectingRangeValues = this.selectedDaterange
      // this.selectedRangeValues = this.selectedDaterange
    }
  }
}
</script>
<style lang="scss" scoped>

.vue-slider-dot{position:absolute;-webkit-transition:all 0s;transition:all 0s;z-index:5}.vue-slider-dot:focus{outline:none}.vue-slider-dot-tooltip{position:absolute;visibility:hidden}.vue-slider-dot-hover:hover .vue-slider-dot-tooltip{visibility:visible}.vue-slider-dot-tooltip-show{visibility:visible}.vue-slider-dot-tooltip-top{top:-10px;left:50%;-webkit-transform:translate(-50%,-100%);transform:translate(-50%,-100%)}.vue-slider-dot-tooltip-bottom{bottom:-10px;left:50%;-webkit-transform:translate(-50%,100%);transform:translate(-50%,100%)}.vue-slider-dot-tooltip-left{left:-10px;top:50%;-webkit-transform:translate(-100%,-50%);transform:translate(-100%,-50%)}.vue-slider-dot-tooltip-right{right:-10px;top:50%;-webkit-transform:translate(100%,-50%);transform:translate(100%,-50%)}
.vue-slider-marks{position:relative;width:100%;height:100%}.vue-slider-mark{position:absolute;z-index:1}.vue-slider-ltr .vue-slider-mark,.vue-slider-rtl .vue-slider-mark{width:0;height:100%;top:50%}.vue-slider-ltr .vue-slider-mark-step,.vue-slider-rtl .vue-slider-mark-step{top:0}.vue-slider-ltr .vue-slider-mark-label,.vue-slider-rtl .vue-slider-mark-label{top:100%;margin-top:10px}.vue-slider-ltr .vue-slider-mark{-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.vue-slider-ltr .vue-slider-mark-step{left:0}.vue-slider-ltr .vue-slider-mark-label{left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%)}.vue-slider-rtl .vue-slider-mark{-webkit-transform:translate(50%,-50%);transform:translate(50%,-50%)}.vue-slider-rtl .vue-slider-mark-step{right:0}.vue-slider-rtl .vue-slider-mark-label{right:50%;-webkit-transform:translateX(50%);transform:translateX(50%)}.vue-slider-btt .vue-slider-mark,.vue-slider-ttb .vue-slider-mark{width:100%;height:0;left:50%}.vue-slider-btt .vue-slider-mark-step,.vue-slider-ttb .vue-slider-mark-step{left:0}.vue-slider-btt .vue-slider-mark-label,.vue-slider-ttb .vue-slider-mark-label{left:100%;margin-left:10px}.vue-slider-btt .vue-slider-mark{-webkit-transform:translate(-50%,50%);transform:translate(-50%,50%)}.vue-slider-btt .vue-slider-mark-step{top:0}.vue-slider-btt .vue-slider-mark-label{top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%)}.vue-slider-ttb .vue-slider-mark{-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.vue-slider-ttb .vue-slider-mark-step{bottom:0}.vue-slider-ttb .vue-slider-mark-label{bottom:50%;-webkit-transform:translateY(50%);transform:translateY(50%)}.vue-slider-mark-label,.vue-slider-mark-step{position:absolute}
.vue-slider{position:relative;-webkit-box-sizing:content-box;box-sizing:content-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;display:block;-webkit-tap-highlight-color:rgba(0,0,0,0)}.vue-slider-rail{position:relative;width:100%;height:100%;-webkit-transition-property:width,height,left,right,top,bottom;transition-property:width,height,left,right,top,bottom}.vue-slider-process{position:absolute;z-index:1}

// + custom


.vue-slider {
  margin:40px 0 ;
  .vue-slider-process{
    background-color: #1ca08d;
  }
  .vue-slider-dot.vue-slider-dot-hover{
    font-size:40px;
    top:-20px;
    .vue-slider-dot-tooltip-inner{


    }
  }
  .vue-slider-dot{
    .vue-slider-dot-tooltip-inner{
      visibility: visible;
      border-color: #1ca08d;
      background-color: #1ca08d;
    }
  }
  .vue-slider-mark {
    overflow: hidden;
    font-size: 10px;
    &:first-child, &:last-child, &:nth-child(14n), {
      overflow: visible;
      //&:nth-last-child(2){
      //  overflow: hidden;
      //}
      & > .vue-slider-mark-step{
        display:block;
        height:300%;
        top:-100%;
      }
      & > .vue-slider-mark-label{
        font-size: 10px;
      }
    }
    &:first-child, &:last-child {
      overflow: visible;
      //margin-top: -40px;
      & > .vue-slider-mark-label{
        font-size: 10px;
      }
    }

    //&.vue-slider-mark-active {
    //  font-size: 12px;
    //  color: #1ca08d;
    //  //& ~ .vue-slider-mark-active {
    //  //  font-size: 12px;
    //  //  color: #ff0055;
    //  //}
    //}
  }
}
</style>
