import moment from 'moment'
import {storageItemsNames} from '../storageItems'
import {min} from '@popperjs/core/lib/utils/math'
import {isDaterangeSelected, selectableDaterange} from './getters'

export const SET_FOCUS = (state, focus) => {
  state.focus = focus
}

export const SET_SAVE_SELECTED_DATERANGE = (state, save) => {
  localStorage.setItem(storageItemsNames.saveSelectedDaterange, JSON.stringify(save))
  if (save === false) {
    localStorage.removeItem(storageItemsNames.selectedDaterange)
  } else {
    localStorage.setItem(storageItemsNames.selectedDaterange, JSON.stringify(state.selectedDaterange))
  }
}


// export const SET_SELECTED_DATERANGE = (state, {dateRange, getters}) => {
//   const dateFormat = 'YYYY-MM-DD'
//
//   if (!dateRange || !(dateRange instanceof Array) || !dateRange.every(dateStr => dateStr) || dateRange.length === 0) {
//     state.selectedDaterange = []
//     localStorage.removeItem(storageItemsNames.selectedDaterange)
//     return
//   }
//
//   const [min, max] = getters.selectableDaterange.map(dateStr => moment(dateStr))
//   let parsedDateRange = dateRange.map(dateStr => moment(dateStr))
//
//   if (!parsedDateRange.every(date => /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(date))) {
//     return
//   }
//   if (moment(parsedDateRange[0]).isAfter(parsedDateRange[1])) {
//     parsedDateRange = parsedDateRange.reverse()
//   }
//   parsedDateRange[0] = parsedDateRange[0].isBefore(min) ? min : parsedDateRange[0]
//   parsedDateRange[0] = parsedDateRange[0].isAfter(max) ? min : parsedDateRange[0]
//   parsedDateRange[1] = parsedDateRange[1].isAfter(max) ? max : parsedDateRange[1]
//   parsedDateRange[1] = parsedDateRange[1].isBefore(min) ? max : parsedDateRange[1]
//
//   const resultDateRange = parsedDateRange.map(date => date.format(dateFormat))
//
//   state.selectedDaterange = resultDateRange
//   if (state.saveSelectedDaterange) {
//     localStorage.setItem(storageItemsNames.selectedDaterange, JSON.stringify(resultDateRange))
//   }
// }

export const SET_SELECTED_DATERANGE = (state, {dateRange, getters}) => {
  state.selectedDaterange = dateRange
  // if (state.saveSelectedDaterange) {
  //   localStorage.setItem(storageItemsNames.selectedDaterange, JSON.stringify(dateRange))
  // }
}

export const SET_PROPERTIES = (state, properties) => {
  localStorage.setItem(storageItemsNames.properties, JSON.stringify(properties))
  state.properties = properties
}

export const SET_SELECTED_PROPERTY = (state, {selectedProperty, getters}) => {
  localStorage.setItem(storageItemsNames.selectedProperty, JSON.stringify(selectedProperty))
  state.selectedProperty = selectedProperty

  const {selectedDaterange} = state
  const {selectableDaterange, isDaterangeSelected} = getters

  if (!isDaterangeSelected || !selectableDaterange) {
    return
  }

  const [start, end] = selectedDaterange
  const [min, max] = selectableDaterange

  if (moment(start).isAfter(end) || moment(start).isBefore(min) || moment(end).isAfter(max)) {
    state.selectedDaterange = []
  }
}

export const RESET_SCOPE = state => {
  state.selectedDaterange = []
  state.properties = []
  state.selectedProperty = {}
  localStorage.removeItem(storageItemsNames.selectedDaterange)
  localStorage.removeItem(storageItemsNames.properties)
  localStorage.removeItem(storageItemsNames.selectedProperty)
}
