import {storageItemsNames} from '../storageItems'

export default {
  settings: null,
  lang: (navigator.language || navigator.userLanguage).toLowerCase() || 'en-us',
  user_persistant: !!localStorage.getItem(storageItemsNames.userPersistent),
  loggedIn: false,
  properties: [],
  redirectAfterLogin: false,
  pending: null,
  error: null,
  success: null,
  selectedProperties: [],
  loading: true,
  token: null,
  user: null,
  APP_SECTION: null,
  cancelSource: null
}
