import langs from './langs'
import login from './login'
import network from './network'
import video from './video'
import global from './global'

export default {
  langs,
  login,
  video,
  global,
  network
}
