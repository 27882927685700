export default {
  GET_NOTIFICATIONS_PENDING (state, status) {
    state.pending = status
  },
  GET_NOTIFICATIONS_ERROR (state, status) {
    state.error = status
  },
  GET_NOTIFICATIONS_SUCCESS (state, payload) {
    state.success = payload.status
    state[payload.field] = payload.data
  },
  INFORMATION_MESSAGES_SUCCESS (state, data) {
    state.messages = data
  }
}
