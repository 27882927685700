export default {
  methods: {
    average (n, d, c) {
      if (d) {
        const average = n / d
        return c ? average.toFixed(c) : Math.round(average)
      } else {
        console.log('MIXIN Numbers err: Average method requires a divider')
      }
    },
    percentage (n, total, c) {
      const pct = (n * 100) / total
      if (pct > 99 && pct < 100) {
        return '>99'
      } else if (pct < 1 && pct > 0) {
        return '<1'
      } else {
        return c ? pct.toFixed(c) : Math.round(pct)
      }
    }
  }
}
