export default {
  pending: null,
  error: null,
  messages: [],
  success: null,
  notifications: [],
  registeredNotification: [],
  redirect: [
    {
      name: 'cookies:drop:beforeConsent',
      routePath: '/trackers',
      filters: [],
      sort: ''
    },
    {
      name: 'cookies:lifetime:moreThan13Month',
      routePath: '/trackers',
      filters: [],
      sort: 'Cookies.lifetimeDays'
    },
    {
      name: 'vendors:cmp:notDeclared',
      routePath: '/vendors',
      tabs: 'Vendors actifs',
      filters: [
        {
          cmp: 'NOT_FOUND'
        }
      ]
    },
    {
      name: 'vendors:status:underQualificication',
      routePath: '/vendors/rules',
      tabs: 'Règle vendors',
      filters: [
      ]
    },

    // Exemple
    // todo : ajouter les configs au fur et a mesure
    {
      name: 'test',
      routePath: '/vendors',
      tabs: 'Vendors actifs',
      sort: 'Vendors.vigilance_rating',
      filters: [
        {
          tcf: true,
          iab: true
        }
      ]
    }
  ]
}
