var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    attrs: {
      "id": "particles-js"
    }
  }), _c('div', {
    staticClass: "auth-container"
  }, [_c('router-view')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }