
export default [
  {
    path: '/',
    name: 'Overview dashbord',
    component: () => import('./../../views/Overview/index'),
    meta: {
      auth: true,
      dateRange: true
    }
  }
]
