export default {
  legend: {
    1: {
      color: '#707070',
      title: 'No data protection law',
      description:
        "This country doesn't ensure an adequate level of data protection recognized by EU.Data transfers to this country require the use of transfer tools."
    },
    2: {
      color: '#D55D78',
      title: 'No adequate country',
      description:
        "This country doesn't ensure an adequate level of data protection recognized by EU.Data transfers to this country require the use of transfer tools.This country has a data protection law."
    },
    3: {
      color: '#FFBD41',
      title: 'Partially adequate country',
      description:
        'This country is partially recognized as ensuring an adequate level of data protection by EU.Data transfers to this country may require the use of transfer tools.'
    },
    4: {
      color: '#81C327',
      title: 'EU or EEA Member country or adequate country',
      description:
        'Data protection in this country is protected by GDPR.\nData transfers to this country do not require the use of transfer tools.'
    }
  },
  countries: {
    AFG: 1,
    ZAF: 3,
    ALB: 3,
    DZA: 1,
    DEU: 6,
    AND: 5,
    AGO: 2,
    ATG: 1,
    SAU: 1,
    ARG: 5,
    ARM: 3,
    AUS: 3,
    AUT: 6,
    AZE: 2,
    BHS: 2,
    BHR: 1,
    BGD: 1,
    BRB: 1,
    BEL: 6,
    BLZ: 1,
    BEN: 3,
    BMU: 1,
    BTN: 1,
    BLR: 1,
    MMR: 1,
    BOL: 1,
    BIH: 3,
    BWA: 1,
    BRA: 2,
    BRN: 1,
    BGR: 6,
    BFA: 3,
    BDI: 1,
    KHM: 1,
    CMR: 1,
    CAN: 4,
    CPV: 3,
    CHL: 2,
    CHN: 2,
    CYP: 6,
    COL: 3,
    COM: 1,
    COG: 1,
    PRK: 1,
    KOR: 3,
    CRI: 3,
    CIV: 3,
    HRV: 6,
    CUB: 1,
    DNK: 6,
    DJI: 1,
    DMA: 1,
    EGY: 1,
    ARE: 1,
    ECU: 1,
    ERI: 1,
    ESP: 6,
    EST: 6,
    USA: 4,
    ETH: 1,
    FJI: 1,
    FIN: 6,
    FRA: 6,
    GAB: 3,
    GMB: 1,
    GEO: 3,
    GHA: 3,
    GIB: 3,
    GRC: 6,
    GRD: 1,
    GRL: 1,
    GTM: 1,
    GGY: 5,
    GIN: 1,
    GNQ: 1,
    GNB: 1,
    GUY: 1,
    GUF: 6,
    HTI: 1,
    HND: 1,
    HKG: 3,
    HUN: 6,
    IMN: 5,
    FRO: 5,
    IND: 2,
    IDN: 2,
    IRQ: 1,
    IRN: 1,
    IRL: 6,
    ISL: 6,
    ISR: 5,
    ITA: 6,
    JAM: 1,
    JPN: 3,
    JEY: 5,
    JOR: 1,
    KAZ: 2,
    KEN: 1,
    KGZ: 2,
    KIR: 1,
    XKX: 3,
    KWT: 1,
    LAO: 1,
    LSO: 2,
    LVA: 6,
    LBN: 1,
    LBR: 1,
    LBY: 1,
    LIE: 6,
    LTU: 6,
    LUX: 6,
    MKD: 3,
    MDG: 2,
    MYS: 2,
    MWI: 1,
    MDV: 1,
    MLI: 3,
    MLT: 6,
    MAR: 3,
    MHL: 1,
    MTQ: 6,
    MUS: 3,
    MRT: 1,
    MYT: 6,
    MEX: 3,
    FSM: 1,
    MDA: 3,
    MCO: 3,
    MNG: 1,
    MNE: 3,
    MOZ: 1,
    NAM: 1,
    NRU: 1,
    NPL: 2,
    NIC: 2,
    NER: 1,
    NGA: 1,
    NOR: 6,
    NZL: 5,
    OMN: 1,
    UGA: 1,
    UZB: 1,
    PAK: 1,
    PLW: 1,
    PSE: 1,
    PAN: 1,
    PNG: 1,
    PRY: 2,
    NLD: 6,
    PER: 3,
    PHL: 3,
    POL: 6,
    PYF: 6,
    PRI: 1,
    PRT: 6,
    QAT: 2,
    REU: 6,
    COD: 1,
    CAF: 1,
    DOM: 2,
    CZE: 6,
    ROU: 6,
    GBR: 6,
    RUS: 2,
    RWA: 1,
    ESH: 1,
    KNA: 1,
    LCA: 2,
    SMR: 2,
    VCT: 2,
    SLB: 1,
    SLV: 1,
    WSM: 1,
    STP: 1,
    SEN: 3,
    SRB: 3,
    SYC: 2,
    SLE: 1,
    SGP: 2,
    SVK: 6,
    SVN: 6,
    SOM: 1,
    SDN: 1,
    SSD: 1,
    LKA: 1,
    SWE: 6,
    CHE: 5,
    SUR: 1,
    SWZ: 1,
    SYR: 1,
    TJK: 1,
    TWN: 2,
    TZA: 1,
    TCD: 1,
    ATF: 6,
    THA: 2,
    TLS: 1,
    TGO: 1,
    TON: 1,
    TTO: 2,
    TUN: 3,
    TKM: 1,
    TUR: 3,
    TUV: 1,
    UKR: 3,
    URY: 5,
    VUT: 1,
    VEN: 1,
    VNM: 2,
    YEM: 2,
    ZMB: 1,
    ZWE: 2
  }
}
