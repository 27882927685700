import { WIDGET_KEYS } from "../../views/Cookies/CookiePolicy/constants"

export const getCookiePolicyByState = (list = []) => {
  return list.reduce(
    (acc, curr) => {
      const { is_exempted, declared_in_tl } = curr

      if (is_exempted) {
        acc[WIDGET_KEYS.exempted].push(curr)
      }

      if (declared_in_tl) {
        acc[WIDGET_KEYS.qualified].push(curr)
      } else {
        acc[WIDGET_KEYS.unqualified].push(curr)
      }

      acc[WIDGET_KEYS.total].push(curr)
      return acc
    },
    {
      [WIDGET_KEYS.exempted]: [],
      [WIDGET_KEYS.qualified]: [],
      [WIDGET_KEYS.unqualified]: [],
      [WIDGET_KEYS.total]: [],
    }
  )
}
