import base from '@/views/Login/Index'
export default [
  {
    path: '/auth',
    component: base,
    children: [
      {
        path: '',
        name: 'Auth',
        component: () => import('@/views/Login/LoginJWT')
      },
      {
        path: '/auth/login',
        name: 'Auth log in',
        component: () => import('@/views/Login/LoginJWT')
      },
      {
        path: '/auth/forgot-password',
        name: 'Auh forgot password',
        component: () => import('@/views/Login/ForgotPassword')
      },
      {
        path: '/auth/reset-password/:token',
        name: 'Auth reset password',
        component: () => import('@/views/Login/ResetPassword')
      },
      {
        path: '/auth/*',
        redirect: '/auth/'
      }
    ]
  }
]
