import ApiConnector from "./index"

class ApiCookiesConnector extends ApiConnector {
  get(token, params = {}) {
    params.unique = true
    return this._axios({ token, params, method: "get" })
  }

  getObservedDeclaredPrivacyPolicyTrackerList(token, params = {}) {
    return this._axios({
      token,
      params,
      method: "get",
      specialUrl: "api/declarePrivacypolicytrackerlists/observedTrackers",
    })
  }

  getDeclaredPrivacyPolicyTrackerList(token, params = {}) {
    return this._axios({
      token,
      params,
      method: "get",
      specialUrl: "api/declarePrivacypolicytrackerlists/declaredTrackers",
    })
  }

  addCookieToPolicyList(token, data) {
    return this._axios({
      token,
      method: "post",
      data,
      specialUrl: "api/declarePrivacypolicytrackerlists/add",
    })
  }

  exemptForProperty(token, data) {
    return this._axios({
      token,
      method: "post",
      data,
      specialUrl: "api/declarePrivacypolicytrackerlists/exemptForProperty",
    })
  }

  removeCookieFromPolicyList(token, data) {
    return this._axios({
      token,
      method: "post",
      data,
      specialUrl: "api/declarePrivacypolicytrackerlists/delete",
    })
  }

  //@TODO: add three other methods here
  getComplianceIndexDailies(token, params = {}) {
    return this._axios({
      token,
      params,
      method: "get",
      specialUrl: "api/complianceindexDailies",
    })
  }
  hasCookieConsent(token, params = {}) {
    return this._axios({
      token,
      params,
      method: "get",
      specialUrl: "api/hasCookieConsent",
    })
  }
  getComplianceScoreDesc(token, params = { getThresholdValues: true }) {
    return this._axios({
      token,
      params,
      method: "get",
      specialUrl: "api/complianceindexParams",
    })
  }
  getPgTrackers(token, params) {
    return this._axios({
      token,
      params,
      method: "get",
      specialUrl: "api/pg_trackers",
    })
  }
  getPgRequests(token, params) {
    return this._axios({
      token,
      params,
      method: "get",
      specialUrl: "api/pg_requests",
    })
  }
  exportPgTrackers(token, params) {
    params.limit = 10000
    params.unique = true
    const options = {
      headers: {
        Accept: "application/octet-stream",
      },
      responseType: "blob",
    }
    return this._axios({
      token,
      params,
      method: "get",
      csv: true,
      axiosOptions: options,
      specialUrl: "api/pg_trackers",
    })
  }
  exportPgRequests(token, params) {
    params.limit = 10000
    params.unique = true
    const options = {
      headers: {
        Accept: "application/octet-stream",
      },
      responseType: "blob",
    }
    return this._axios({
      token,
      params,
      method: "get",
      csv: true,
      axiosOptions: options,
      specialUrl: "api/pg_requests",
    })
  }
  export(token, params) {
    params.limit = 10000
    params.unique = true
    const options = {
      headers: {
        Accept: "application/octet-stream",
      },
      responseType: "blob",
    }
    return this._axios({
      token,
      params,
      method: "get",
      csv: true,
      axiosOptions: options,
    })
  }
}
export default ApiCookiesConnector
