import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './messages'
import moment from 'moment'

Vue.use(VueI18n)

// const locale =
//   (navigator.language || navigator.userLanguage).toLowerCase() || 'en-us'
const locale = 'en-us'

moment.locale(locale)

const i18n = new VueI18n({
  locale,
  messages
})

export default i18n
