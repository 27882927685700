
export default [
  {
    path: '/compliance-index',
    name: 'Compliance-index view',
    component: () => import('./../../views/ComplianceIndex/index'),
    meta: {
      auth: true,
      dateRange: true
    }
  },
  {
    path: '/compliance-index/score',
    name: 'Compliance-index score',
    component: () => import('./../../views/ComplianceIndex/Score'),
    meta: {
      auth: true,
      dateRange: true
    }
  }
]
