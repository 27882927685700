// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

import store from './store'
import router from './router'
import i18n from './i18n'
import App from './App'

import axios from './plugins/axios'

import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import '@/assets/styles/theme.less'
import { locale } from 'view-design'
import lang from 'view-design/dist/locale/en-US'

// configure language
locale(lang)
import analytics from './plugins/analytics'

import numbers from './mixins/numbers'
import translate from './mixins/translate'


import 'vue-search-select/dist/VueSearchSelect.css'
// import VueLeaflet from '@vdcrea/vue-leaflet'
// import '@vdcrea/vue-leaflet/dist/vue-leaflet.css'


axios()
// iview()

// leaflet()
// layouts()
analytics()
Vue.use(ViewUI)
// Vue.use(VueLeaflet)
Vue.config.productionTip = false
Vue.mixin(numbers)
Vue.mixin(translate)

export const eventBus = new Vue()
/* eslint-disable no-new */
new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
