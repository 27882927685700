export default {
  /**
   * Transforme la vigilance numerique (1-5) en lettre
   * @param {status} Number  vigilance numérique (1 a 5)
   * @retur"n {String} retourne la vigilance (A à E)
   */
  vendorVigilanceToLetter: state => (status) => {
    if (!!status === false) return '-'
    return String.fromCharCode(parseInt(Math.ceil(status)) + 64)
  },
  Lvigilance: state => (status) => {
    if (status < 0.2) {
      return 'Absolue'
    } else if (status < 0.4) {
      return 'Extreme'
    } else if (status < 0.6) {
      return 'Élevée'
    } else if (status < 0.8) {
      return 'Modérée'
    }
    return 'Faible'
  },
  riskColor: state => state.riskColor,
  alphaRiskColor: state => state.alphaRiskColor,
  options: state => state.options,
  showCategoryHelper: state => state.displayCategoryHelper,
  declaredVendors: state => state.vendorListDeclaredVendors,
  vendorList: state => state.draftVendorList,
  nonDeclaredDraftVendorList: state => {
    // return state.draftVendorList.filter(elm => !elm.declared_in_vl)
    return state.draftVendorList.filter(elm => !elm.detected_in_cmp && !elm.duplicate)
  },
  draftVendorList: state => {
    // return state.draftVendorList.filter(elm => elm.declared_in_vl)
    const draft = state.draftVendorList.filter(elm => (elm.detected_in_cmp || elm.declared_in_vl) && !elm.duplicate)
    const sorted = draft.sort(function(a,b){return b.declared_in_vl-a.declared_in_vl}) // b - a for reverse sort

    return sorted
  },
  baseVendorListState: state => state.baseVendorListState
}
