export default {
  global: {
    yes: 'Oui',
    no: 'Non',
    submit: 'Valider',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    no_data: 'Aucune donnée spécifiée'
  },
  menu: {
    home: 'Accueil',
    overview: 'Aperçu',
    history: 'Historique',
    account: 'Profil'
  },
  history: {
    title: 'Historique des évaluations',
    last_n: 'Les dix dernières évaluations'
  },
  detail: {
    configuration: 'Description',
    setup_summary: 'Résumé configuration',
    save_configuration: 'Sauvegarder',
    recheck_configuration: 'Analyser',
    creative: 'Création',
    summary: 'Caractéristiques',
    detailed: "Détails de l'évaluation",
    control: "Evaluation d'une url VAST / VPAID",
    start_control: "Lancer l'évaluation",
    results: "Resultats de l'évaluation",
    new_control: 'Nouvelle évaluation',
    save_control: "Sauvegarder l'évaluation",
    edit_control: "Editer l'évaluation",
    redo_control: "Relancer l'évaluation",
    preview: 'Aperçu',
    vendors_typo: 'Typologie de domaine',
    active_vendors: 'Domaines présents',
    sale_types: 'Type de vente',
    agency: 'Agence',
    ads_found: "Nombre d'annonces",
    redirects_found: 'Présence de redirection',
    vpaid_found: "Présence d'un VPAID",
    vpaid_viewability: "Présence d'encapsuleur VPAID",
    advertiser: 'Annonceur',
    nb: 'Occurences',
    domain: 'Domaine',
    name: 'Nom',
    campaign: 'Campagne',
    campaign_name: 'Nom de campagne',
    tradingdesk: 'Trading Desk',
    ads_count: 'Publicités',
    redirects_count: 'Redirections',
    vpaid_count: 'VPAID',
    viewability_count: 'Visibilité',
    last_checked_at: 'Dernière analyse le ',
    vast_xml: 'VAST xml',
    vast_url: 'VAST url',
    last_check_date: 'Dernière évaluation',
    url_vast: 'Url du VAST',
    unknown_vendor: 'Acteur inconnu',
    unknown_category: 'Categorie inconnue',
    url_list: 'Liste complète des urls présentes',
    tracking_url: 'Liste des événements video (tracking event)',
    event_name: "Nom de l'événement",
    vendor: 'Domaine',
    analyze: 'Évaluation',
    ad_duration: "Durée de l'annonce"
  }
}
