/* Env
 if set to prod, the app use analytics
*/
// todo : use __ENABLE_TRACKING__
// export const env = 'prod' // 'preprod' \ 'prod'
/* Google analytics key */
// export const ga = 'UA-127170819-2'
// export const ga = process.env.VUE_APP_GATRACKID

/* API baseURL */
export const api = {
  root: process.env.VUE_APP_APIBASE /* 'http://localhost:8888/facettes_back/' */
  // preprod: 'https://staging-api.facettes.io/',
  // prod: 'https://api.facettes.io/'
}

/* Charts color palette */
export const colors = [
  { ref: 'RED', hex: '#ED1C24' },
  { ref: 'PINK', hex: '#CE0061' },
  { ref: 'ORANGE', hex: '#F4512C' },
  { ref: 'YELLOW', hex: '#F28E00' },
  { ref: 'GREEN', hex: '#87BC27' },
  { ref: 'TURQUESE', hex: '#00BFB3' },
  { ref: 'BLUE', hex: '#009FE3' },
  { ref: 'PURPLE', hex: '#555CA2' }
]

/* Alerts Info */
export const CONTACT_COPY = 'ledger@agnostik.io'
export const QUALIF_TAG = 'Qualification in progress'
export const ALERT_TYPE = Object.freeze({
  VENDORS_CONTROL: '7',
  VENDORS_COMPLIANCE: '2',
  COOKIES_COMPLIANCE: '1',
  REQUESTS_CONTROL: '6',
  REQUESTS_PERFORMANCE: '8'
})

export const ALERT_TYPE_N = Object.freeze({
  2: {
    ICON: 'icon--document'
  },
  1: {
    ICON: 'icon--cookie'
  },
  6: {
    ICON: 'icon--unlocked'
  },
  8: {
    ICON: 'icon--snail'
  }
})

export const TCFV2 =  {
  // mapping from didomi purposes/specialfeatures ids is from https://developers.didomi.io/cmp/mobile-sdk/consent-notice/vendors-and-purposes#standard-purposes
  FEATURES_AND_PURPOSES : {
    "purposes": {
      "1": {
        "id": 1,
        "didomiPurposeIds": ["cookies"],
        "name": "Store and/or access information on a device",
        "description": "Cookies, device identifiers, or other information can be stored or accessed on your device for the purposes presented to you.",
        "descriptionLegal": "Vendors can:\n* Store and access information on the device such as cookies and device identifiers presented to a user."
      },
      "2": {
        "id": 2,
        "didomiPurposeIds": ["select_basic_ads"],
        "name": "Select basic ads",
        "description": "Ads can be shown to you based on the content you\u2019re viewing, the app you\u2019re using, your approximate location, or your device type.",
        "descriptionLegal": "To do basic ad selection vendors can:\n* Use real-time information about the context in which the ad will be shown, to show the ad, including information about the content and the device, such as: device type and capabilities, user agent, URL, IP address\n* Use a user\u2019s non-precise geolocation data\n* Control the frequency of ads shown to a user.\n* Sequence the order in which ads are shown to a user.\n* Prevent an ad from serving in an unsuitable editorial (brand-unsafe) context\nVendors cannot:\n* Create a personalised ads profile using this information for the selection of future ads without a separate legal basis to create a personalised ads profile.\n* N.B. Non-precise means only an approximate location involving at least a radius of 500 meters is permitted."
      },
      "3": {
        "id": 3,
        "didomiPurposeIds": ["create_ads_profile"],
        "name": "Create a personalised ads profile",
        "description": "A profile can be built about you and your interests to show you personalised ads that are relevant to you.",
        "descriptionLegal": "To create a personalised ads profile vendors can:\n* Collect information about a user, including a user's activity, interests, demographic information, or location, to create or edit a user profile for use in personalised advertising.\n* Combine this information with other information previously collected, including from across websites and apps, to create or edit a user profile for use in personalised advertising."
      },
      "4": {
        "id": 4,
        "didomiPurposeIds": ["select_personalized_ads"],
        "name": "Select personalised ads",
        "description": "Personalised ads can be shown to you based on a profile about you.",
        "descriptionLegal": "To select personalised ads vendors can:\n* Select personalised ads based on a user profile or other historical user data, including a user\u2019s prior activity, interests, visits to sites or apps, location, or demographic information."
      },
      "5": {
        "id": 5,
        "didomiPurposeIds": ["create_content_profile"],
        "name": "Create a personalised content profile",
        "description": "A profile can be built about you and your interests to show you personalised content that is relevant to you.",
        "descriptionLegal": "To create a personalised content profile vendors can:\n* Collect information about a user, including a user's activity, interests, visits to sites or apps, demographic information, or location, to create or edit a user profile for personalising content.\n* Combine this information with other information previously collected, including from across websites and apps, to create or edit a user profile for use in personalising content."
      },
      "6": {
        "id": 6,
        "didomiPurposeIds": ["select_personalized_content"],
        "name": "Select personalised content",
        "description": "Personalised content can be shown to you based on a profile about you.",
        "descriptionLegal": "To select personalised content vendors can:\n* Select personalised content based on a user profile or other historical user data, including a user\u2019s prior activity, interests, visits to sites or apps, location, or demographic information."
      },
      "7": {
        "id": 7,
        "didomiPurposeIds": ["measure_ad_performance"],
        "name": "Measure ad performance",
        "description": "The performance and effectiveness of ads that you see or interact with can be measured.",
        "descriptionLegal": "To measure ad performance vendors can:\n* Measure whether and how ads were delivered to and interacted with by a user\n* Provide reporting about ads including their effectiveness and performance\n* Provide reporting about users who interacted with ads using data observed during the course of the user's interaction with that ad\n* Provide reporting to publishers about the ads displayed on their property\n* Measure whether an ad is serving in a suitable editorial environment (brand-safe) context\n* Determine the percentage of the ad that had the opportunity to be seen and the duration of that opportunity\n* Combine this information with other information previously collected, including from across websites and apps\nVendors cannot:\n*Apply panel- or similarly-derived audience insights data to ad measurement data without a Legal Basis to apply market research to generate audience insights (Purpose 9)"
      },
      "8": {
        "id": 8,
        "didomiPurposeIds": ["measure_content_performance"],
        "name": "Measure content performance",
        "description": "The performance and effectiveness of content that you see or interact with can be measured.",
        "descriptionLegal": "To measure content performance vendors can:\n* Measure and report on how content was delivered to and interacted with by users.\n* Provide reporting, using directly measurable or known information, about users who interacted with the content\n* Combine this information with other information previously collected, including from across websites and apps.\nVendors cannot:\n* Measure whether and how ads (including native ads) were delivered to and interacted with by a user.\n* Apply panel- or similarly derived audience insights data to ad measurement data without a Legal Basis to apply market research to generate audience insights (Purpose 9)"
      },
      "9": {
        "id": 9,
        "didomiPurposeIds": ["market_research"],
        "name": "Apply market research to generate audience insights",
        "description": "Market research can be used to learn more about the audiences who visit sites/apps and view ads.",
        "descriptionLegal": "To apply market research to generate audience insights vendors can:\n* Provide aggregate reporting to advertisers or their representatives about the audiences reached by their ads, through panel-based and similarly derived insights.\n* Provide aggregate reporting to publishers about the audiences that were served or interacted with content and/or ads on their property by applying panel-based and similarly derived insights.\n* Associate offline data with an online user for the purposes of market research to generate audience insights if vendors have declared to match and combine offline data sources (Feature 1)\n* Combine this information with other information previously collected including from across websites and apps. \nVendors cannot:\n* Measure the performance and effectiveness of ads that a specific user was served or interacted with, without a Legal Basis to measure ad performance.\n* Measure which content a specific user was served and how they interacted with it, without a Legal Basis to measure content performance."
      },
      "10": {
        "id": 10,
        "didomiPurposeIds": ["improve_products"],
        "name": "Develop and improve products",
        "description": "Your data can be used to improve existing systems and software, and to develop new products",
        "descriptionLegal": "To develop new products and improve products vendors can:\n* Use information to improve their existing products with new features and to develop new products\n* Create new models and algorithms through machine learning\nVendors cannot:\n* Conduct any other data processing operation allowed under a different purpose under this purpose"
      }
    },
    "specialPurposes": {
      "1": {
        "id": 1,
        // "didomiPurposeIds": ["analytics"],
        "name": "Ensure security, prevent fraud, and debug",
        "description": "Your data can be used to monitor for and prevent fraudulent activity, and ensure systems and processes work properly and securely.",
        "descriptionLegal": "To ensure security, prevent fraud and debug vendors can:\n* Ensure data are securely transmitted\n* Detect and prevent malicious, fraudulent, invalid, or illegal activity.\n* Ensure correct and efficient operation of systems and processes, including to monitor and enhance the performance of systems and processes engaged in permitted purposes\nVendors cannot:\n* Conduct any other data processing operation allowed under a different purpose under this purpose.\nNote: Data collected and used to ensure security, prevent fraud, and debug may include automatically-sent device characteristics for identification, precise geolocation data, and data obtained by actively scanning device characteristics for identification without separate disclosure and/or opt-in."
      },
      "2": {
        "id": 2,
        // "didomiPurposeIds": ["ad_delivery"],
        "name": "Technically deliver ads or content",
        "description": "Your device can receive and send information that allows you to see and interact with ads and content.",
        "descriptionLegal": "To deliver information and respond to technical requests vendors can:\n* Use a user\u2019s IP address to deliver an ad over the internet\n* Respond to a user\u2019s interaction with an ad by sending the user to a landing page\n* Use a user\u2019s IP address to deliver content over the internet\n* Respond to a user\u2019s interaction with content by sending the user to a landing page\n* Use information about the device type and capabilities for delivering ads or content, for example, to deliver the right size ad creative or video file in a format supported by the device\nVendors cannot:\n* Conduct any other data processing operation allowed under a different purpose under this purpose"
      }
    },
    "features": {
      "1": {
        "id": 1,
        "name": "Match and combine offline data sources",
        "description": "Data from offline data sources can be combined with your online activity in support of one or more purposes",
        "descriptionLegal": "Vendors can:\n* Combine data obtained offline with data collected online in support of one or more Purposes or Special Purposes."
      },
      "2": {
        "id": 2,
        "name": "Link different devices",
        "description": "Different devices can be determined as belonging to you or your household in support of one or more of purposes.",
        "descriptionLegal": "Vendors can:\n* Deterministically determine that two or more devices belong to the same user or household\n* Probabilistically determine that two or more devices belong to the same user or household\n* Actively scan device characteristics for identification for probabilistic identification if users have allowed vendors to actively scan device characteristics for identification (Special Feature 2)"
      },
      "3": {
        "id": 3,
        "name": "Receive and use automatically-sent device characteristics for identification",
        "description": "Your device might be distinguished from other devices based on information it automatically sends, such as IP address or browser type.",
        "descriptionLegal": "Vendors can:\n* Create an identifier using data collected automatically from a device for specific characteristics, e.g. IP address, user-agent string.\n* Use such an identifier to attempt to re-identify a device.\nVendors cannot:\n* Create an identifier using data collected via actively scanning a device for specific characteristics, e.g. installed font or screen resolution without users\u2019 separate opt-in to actively scanning device characteristics for identification.\n* Use such an identifier to re-identify a device."
      }
    },
    "specialFeatures": {
      "1": {
        "id": 1,
        "didomiFeatureIds": ["geolocation_data"],
        "name": "Use precise geolocation data",
        "description": "Your precise geolocation data can be used in support of one or more purposes. This means your location can be accurate to within several meters.",
        "descriptionLegal": "Vendors can:\n* Collect and process precise geolocation data in support of one or more purposes.\nN.B. Precise geolocation means that there are no restrictions on the precision of a user\u2019s location; this can be accurate to within several meters."
      },
      "2": {
        "id": 2,
        "didomiFeatureIds": ["device_characteristics"],
        "name": "Actively scan device characteristics for identification",
        "description": "Your device can be identified based on a scan of your device's unique combination of characteristics.",
        "descriptionLegal": "Vendors can:\n* Create an identifier using data collected via actively scanning a device for specific characteristics, e.g. installed fonts or screen resolution.\n* Use such an identifier to re-identify a device."
      }
    }
  }
}

export const DATE_PICKER_MAX_DATE = '2024-09-09'
