import {storageItemsNames} from '../storageItems'
import store from '@/store'

export default {
  GET_USER_PENDING (state, payload) {
    state.pending = payload.state
  },
  GET_USER_ERROR (state, status) {
    state.error = status
  },
  GET_USER_SUCCESS (state, payload) {
    state.success = payload.status
  },
  SET_LOADING (state, loading) {
    console.log('false')
    state.loading = loading
  },
  SET_TOKEN (state, token) {
    if (token.persistant) {
      localStorage.setItem(storageItemsNames.token, JSON.stringify(token.token))
    }
    state.token = token.token
    state.loggedIn = true
  },
  SET_USER (state, user) {
    if (user.persistant) {
      localStorage.setItem(storageItemsNames.user, JSON.stringify(user.user))
    }
    state.loggedIn = true
    state.user = user.user
  },
  SET_USER_PERSISTANT (state, persistant) {
    localStorage.setItem(storageItemsNames.userPersistent, persistant)
    state.user_persistant = persistant
    state.loggedIn = true
  },
  SET_USER_PROPERTIES (state, properties) {
    state.properties = properties
    localStorage.setItem(storageItemsNames.properties, JSON.stringify(properties))
  },
  LOGOUT_USER (state, status) {
    if (status) {
      state.token = null
      state.loggedIn = false
      state.user = {}
      state.user_persistant = false

    }
  },
  CLEAN_STORAGE (state) {
    clearAllStore()
    localStorage.removeItem(storageItemsNames.lastMessageOpened)
    localStorage.removeItem('fa7-daterange')
    localStorage.removeItem(storageItemsNames.user)
    localStorage.removeItem(storageItemsNames.token)
    localStorage.removeItem(storageItemsNames.userPersistent)
    localStorage.removeItem(storageItemsNames.properties)
    localStorage.removeItem(storageItemsNames.selectedProperty)
  }
}
const clearAllStore = () => {

  let state = store.state
  Object.keys(state).forEach(module => {
    Object.keys(module).forEach(field => {
      field = null
    })
  })
}