import ApiConnector from './index'

class ApiVastconfigsConnector extends ApiConnector {
  getSaleTypes (token, params) {
    return this._axios({token, method: 'get', params, specialUrl: '/saletypes'})
  }
  searchByAgncy (token, params) {
    return this._axios({token, method: 'get', params, specialUrl: '/agencies'})
  }
  searchByAdv (token, params) {
    return this._axios({token, method: 'get', params, specialUrl: '/advertisers'})
  }
}

export default ApiVastconfigsConnector