export const setProperties = (state, properties) => {
  state.properties = properties
  state.propertiesIndex = Object.keys(properties)
}
// NOTE: Demo only
export const setCustomer = (state, customer) => {
  state.customer = customer
}
export const setCustomers = (state, customers) => {
  const customersIndex = Object.keys(customers)
  state.customers = customers
  state.customersIndex = customersIndex
}

export const flushOptions = (state) => {
  state.properties = []
  state.propertiesIndex = []
}
