import ApiCookiesConnector from "./../api/cookie"

const apiCookie = new ApiCookiesConnector("cookies")

const proxy = ({ fn, call, callback, uid }) =>
  fn(
    "makeCall",
    { namespace: "cookie", promise: call, uid, callback },
    { root: true }
  )

export default {
  /**
   * Uid is an id for mutation, it needed to find wich call is loading or not
   */
  getCookies: function ({ dispatch, rootGetters }, { params, uid }) {
    // define parameters if function was not call with object parameters
    if (!params) params = arguments[1]
    // const copy = Object.assign({}, params)
    return proxy({
      fn: dispatch,
      call: apiCookie.get(rootGetters["user/token"], params),
      uid,
    })
  },

  // @TODO: add 3 other action methods here.
  getObservedDeclaredPrivacyPolicyTrackerList: function (
    { dispatch, rootGetters },
    params
  ) {
    return proxy({
      fn: dispatch,
      call: apiCookie.getObservedDeclaredPrivacyPolicyTrackerList(
        rootGetters["user/token"],
        params
      ),
    })
  },

  getDeclaredPrivacyPolicyTrackerList: function (
    { dispatch, rootGetters },
    params
  ) {
    return proxy({
      fn: dispatch,
      call: apiCookie.getDeclaredPrivacyPolicyTrackerList(
        rootGetters["user/token"],
        params
      ),
    })
  },
  removeCookieFromPolicyList: function ({ dispatch, rootGetters }, data) {
    return proxy({
      fn: dispatch,
      call: apiCookie.removeCookieFromPolicyList(rootGetters["user/token"], data),
    })
  },
  addCookieToPolicyList: function ({ dispatch, rootGetters }, data) {
    return proxy({
      fn: dispatch,
      call: apiCookie.addCookieToPolicyList(rootGetters["user/token"], data),
    })
  },
  exemptForProperty: function ({ dispatch, rootGetters }, data) {
    return proxy({
      fn: dispatch,
      call: apiCookie.exemptForProperty(rootGetters["user/token"], data),
    })
  },

  getComplianceScoreDesc: function ({ dispatch, rootGetters }) {
    return proxy({
      fn: dispatch,
      call: apiCookie.getComplianceScoreDesc(rootGetters["user/token"]),
    })
  },
  getPgRequests: function ({ dispatch, rootGetters }, { params, uid }) {
    return proxy({
      fn: dispatch,
      call: apiCookie.getPgRequests(rootGetters["user/token"], params),
      uid,
    })
  },
  getPgTrackers: function ({ dispatch, rootGetters }, { params, uid }) {
    return proxy({
      fn: dispatch,
      call: apiCookie.getPgTrackers(rootGetters["user/token"], params),
      uid,
    })
  },
  getComplianceIndexDailies: function (
    { dispatch, rootGetters },
    { params = {}, uid }
  ) {
    return proxy({
      fn: dispatch,
      call: apiCookie.getComplianceIndexDailies(
        rootGetters["user/token"],
        params
      ),
      uid,
    })
  },
  exportCsv: function ({ dispatch, rootGetters }, params) {
    return apiCookie.export(rootGetters["user/token"], params)
  },
  exportPgTrackers: function ({ dispatch, rootGetters }, params) {
    return apiCookie.exportPgTrackers(rootGetters["user/token"], params)
  },
  exportPgRequests: function ({ dispatch, rootGetters }, params) {
    return apiCookie.exportPgRequests(rootGetters["user/token"], params)
  },

  generateDataObject ({rootGetters},  payload) {
    return {
      properties: [rootGetters['scope/selectedProperty'].id],
      hash_namehosttype: payload.hash_namehosttype,
      identifier: payload.identifier,
      vendor_id: payload.vendor_id,
      domain: payload.domain,
      type: payload.type,
      max_age_seconds: payload.max_age_seconds,
      exempted: !!payload?.exemption_declarations.length,
      vendor_name: payload?.vendor?.name  || '', //@todo:  ask henry here
      purposes: payload.purposes.length ? payload.purposes : [],
      exemption_declarations: payload.exemption_declarations.length ? payload.exemption_declarations : null
    }
  },
  async handleAddTrackerToCookiePolicy ({dispatch, commit}, {tracker = null, purposes = [], exemption = []}) {
    const payload = JSON.parse(JSON.stringify(tracker))
    payload.purposes = purposes
    payload.exemption_declarations = exemption
    payload.declared_in_tl = 1
    const data = await dispatch('generateDataObject', payload)
    return dispatch('addCookieToPolicyList', data)
      .then(r => {
        if (r.success) {
          commit('QUALIFY_NEW_COOKIE', payload)
        }
        return r
      })
  },
  async handleAddDeclaredExemptedToPropertyExemptedTracker ({dispatch, commit}, {properties = null}) {
    const payload = JSON.parse(JSON.stringify(properties))
    console.log(payload)
    // payload.purposes = purposes
    // payload.exemption_declarations = exemption
    // payload.declared_in_tl = 1
    // const data = await dispatch('generateDataObject', payload)

    const data = {
      properties: properties
    }
    return dispatch('exemptForProperty', data)
      .then(r => {
        // if (r.success) {
        //   commit('QUALIFY_NEW_COOKIE', payload)
        // }
        return r
      })
  }
}
