export default [
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('@/views/Notifications/Index'),
    meta: {
      auth: true,
      dateRange: true
    },
    children: [
      {
        path: '/notifications/:id',
        name: 'Notifications view',
        component: () => import('@/views/Notifications/Index'),
        meta: {
          auth: true,
          dateRange: true
        }
      }
    ]
  },
  {
    path: '/weekly-detail',
    name: 'Notifications detail view',
    component: () => import('@/views/Notifications/WeeklyDetail'),
    meta: {
      auth: true,
      dateRange: true
    }
  },
  {
    path: 'news',
    name: 'News',
    component: () => import('@/views/Notifications/News'),
    meta: {
      auth: true,
      dateRange: true
    }
  }
]
