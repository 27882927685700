var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "property-seltc2"
  }, [_c('Poptip', {
    ref: "propertyPop",
    staticStyle: {
      "margin-right": "16px"
    },
    attrs: {
      "placement": "bottom-start"
    },
    model: {
      value: _vm.isPropertypoptipOpen,
      callback: function callback($$v) {
        _vm.isPropertypoptipOpen = $$v;
      },
      expression: "isPropertypoptipOpen"
    }
  }, [_c('Button', {
    staticClass: "property-popper",
    staticStyle: {
      "text-align": "left"
    },
    style: {
      width: _vm.selectedProperty.id ? '218px' : '218px'
    },
    attrs: {
      "size": "large"
    }
  }, [_c('i', {
    staticClass: "fas fa-layer-group"
  }), _c('span', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v(_vm._s(_vm.truncate(_vm.selectedProperty.displayName ? _vm.selectedProperty.displayName : 'Website properties', 20)))]), _c('span', {
    staticStyle: {
      "position": "absolute",
      "right": "0px"
    }
  }, [_c('i', {
    staticClass: "fas fa-sort-down"
  })])]), _c('div', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('Row', {
    staticStyle: {
      "max-width": "420px"
    },
    attrs: {
      "type": "flex"
    }
  }, [_c('Col', {
    attrs: {
      "span": "8"
    }
  }, [_c('Row', {
    staticStyle: {
      "padding-top": "5px"
    }
  }, [_vm.properties.length > 6 || _vm.userHasRole('super-admin') ? _c('Checkbox', {
    model: {
      value: _vm.propertiesFilter.onlyEnableProperties,
      callback: function callback($$v) {
        _vm.$set(_vm.propertiesFilter, "onlyEnableProperties", $$v);
      },
      expression: "propertiesFilter.onlyEnableProperties"
    }
  }, [_c('i', {
    staticClass: "fas fa-check-square",
    staticStyle: {
      "margin-left": "5px",
      "font-size": "12px"
    }
  }), _vm._v(" "), _c('span', {
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_vm._v("Show only enabled properties")])]) : _vm._e(), _vm.properties.length > 6 ? _c('Checkbox', {
    model: {
      value: _vm.propertiesFilter.onlyEnableCollectsProperties,
      callback: function callback($$v) {
        _vm.$set(_vm.propertiesFilter, "onlyEnableCollectsProperties", $$v);
      },
      expression: "propertiesFilter.onlyEnableCollectsProperties"
    }
  }, [_c('i', {
    staticClass: "fas fa-layer-group",
    staticStyle: {
      "margin-left": "5px",
      "font-size": "12px"
    }
  }), _c('span', {
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_vm._v("Show only collection enabled properties")])]) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between"
    }
  }, [_c('Checkbox', {
    staticStyle: {
      "margin-right": "20px"
    },
    model: {
      value: _vm.propertiesFilter.freshData,
      callback: function callback($$v) {
        _vm.$set(_vm.propertiesFilter, "freshData", $$v);
      },
      expression: "propertiesFilter.freshData"
    }
  }, [_c('i', {
    staticClass: "fas fa-layer-group",
    staticStyle: {
      "margin-left": "5px",
      "font-size": "12px",
      "color": "#81c327"
    }
  }), _vm._v(" "), _c('span', {
    staticStyle: {
      "margin-left": "7px"
    }
  }, [_vm._v("Fresh Data")])]), _c('Checkbox', {
    staticStyle: {
      "margin-right": "20px"
    },
    model: {
      value: _vm.propertiesFilter.staleData,
      callback: function callback($$v) {
        _vm.$set(_vm.propertiesFilter, "staleData", $$v);
      },
      expression: "propertiesFilter.staleData"
    }
  }, [_c('i', {
    staticClass: "fas fa-layer-group",
    staticStyle: {
      "margin-left": "5px",
      "font-size": "12px",
      "color": "#ffbd41"
    }
  }), _vm._v(" "), _c('span', {
    staticStyle: {
      "margin-left": "7px"
    }
  }, [_vm._v("Stale data")])]), _c('Checkbox', {
    staticStyle: {
      "margin-right": "20px"
    },
    model: {
      value: _vm.propertiesFilter.noData,
      callback: function callback($$v) {
        _vm.$set(_vm.propertiesFilter, "noData", $$v);
      },
      expression: "propertiesFilter.noData"
    }
  }, [_c('i', {
    staticClass: "fas fa-layer-group",
    staticStyle: {
      "margin-left": "5px",
      "font-size": "12px",
      "color": "#d55d78"
    }
  }), _vm._v(" "), _c('span', {
    staticStyle: {
      "margin-left": "7px"
    }
  }, [_vm._v("No data")])])], 1)], 1)], 1)], 1), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "10px",
      "display": "block"
    }
  }, [_vm._v("(count : " + _vm._s(_vm.filteredPropertyList.length) + ")")]), _vm.selectedProperty.displayName ? _c('span', {
    on: {
      "click": function click($event) {
        return _vm.selectProperty({});
      }
    }
  }, [_c('Divider'), _vm._v(" Currently selected : "), _c('strong', {
    staticStyle: {
      "color": "#1CA08D",
      "margin-left": "5px"
    }
  }, [_vm._v(_vm._s(_vm.selectedProperty ? _vm.selectedProperty.displayName : ''))])], 1) : _vm._e(), _c('Divider'), _vm.properties.length > 6 || _vm.userHasRole('super-admin') ? _c('Input', {
    ref: "inputProp",
    staticClass: "property-select-input",
    staticStyle: {
      "width": "100%",
      "margin-bottom": "10px"
    },
    attrs: {
      "prefix": "ios-search",
      "autofocus": true,
      "suffix": 'hello',
      "placeholder": "Search"
    },
    on: {
      "on-change": _vm.searchPropertiesByName
    },
    model: {
      value: _vm.propertyNameFilter,
      callback: function callback($$v) {
        _vm.propertyNameFilter = $$v;
      },
      expression: "propertyNameFilter"
    }
  }) : _vm._e(), _c('Row', {
    staticStyle: {
      "max-height": "200px",
      "width": "420px",
      "overflow": "auto",
      "overflow-x": "hidden"
    }
  }, _vm._l(_vm.filteredPropertyList, function (item) {
    return _c('div', {
      key: item.displayName + item.isRemoteSearchResult,
      staticClass: "property-item",
      staticStyle: {
        "width": "100%"
      }
    }, [_c('span', {
      staticStyle: {
        "width": "100%",
        "display": "inline-block"
      },
      on: {
        "click": function click($event) {
          return _vm.selectProperty(item);
        }
      }
    }, [item.isRemoteSearchResult ? _c('i', {
      staticClass: "fas fa-layer-group",
      staticStyle: {
        "margin-left": "5px",
        "font-size": "12px"
      },
      style: {
        'color': _vm.whichDataStatus(item)
      }
    }) : _c('i', {
      staticClass: "fas fa-layer-group",
      staticStyle: {
        "margin-left": "5px",
        "font-size": "12px"
      },
      style: {
        'color': _vm.whichDataStatus(item)
      }
    }), _vm._v(" " + _vm._s(item.displayName) + " "), _vm.userHasRole('super-admin') ? _c('span', {
      staticStyle: {
        "display": "inline-block",
        "width": "20px",
        "text-align": "center",
        "margin-right": "5px",
        "font-size": "12px",
        "float": "right"
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.togglePropertyForUser(item);
        }
      }
    }, [item.isRemoteSearchResult ? _c('i', {
      staticClass: "fas fa-plus",
      style: {
        'color': 'green'
      }
    }) : _c('i', {
      staticClass: "fas fa-minus",
      style: {
        'color': 'orange'
      }
    })]) : _vm._e()])]);
  }), 0)], 1)], 1), _c('Poptip', {
    ref: "datepickerPop",
    staticClass: "poptip-container",
    attrs: {
      "placement": "bottom-start"
    },
    model: {
      value: _vm.isDatepickerpoptipOpen,
      callback: function callback($$v) {
        _vm.isDatepickerpoptipOpen = $$v;
      },
      expression: "isDatepickerpoptipOpen"
    }
  }, [_c('Button', {
    staticStyle: {
      "width": "218px",
      "text-align": "left"
    },
    attrs: {
      "size": "large",
      "disabled": !_vm.selectedProperty.id
    }
  }, [_c('span', [_c('i', {
    staticClass: "fas fa-calendar-alt"
  }), _vm._v(" "), _c('span', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v(" " + _vm._s(_vm.last14CollectedDays) + " ")]), _c('span', {
    staticStyle: {
      "position": "absolute",
      "right": "15px"
    }
  }, [_c('i', {
    staticClass: "fas fa-sort-down"
  })])])]), _c('Row', {
    attrs: {
      "slot": "content",
      "type": "flex"
    },
    slot: "content"
  }, [[_c('Col', {
    staticStyle: {
      "padding-left": "16px",
      "height": "100%"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('div', {
    staticStyle: {
      "border-bottom": "1px solid #ebebeb"
    }
  }, [_c('DatePicker', {
    staticStyle: {
      "width": "240px"
    },
    attrs: {
      "type": "daterange",
      "options": _vm.disabledDates,
      "placement": "bottom-end",
      "placeholder": "Select date"
    },
    on: {
      "on-change": _vm.datepickerHandleChange
    },
    model: {
      value: _vm.datepicker,
      callback: function callback($$v) {
        _vm.datepicker = $$v;
      },
      expression: "datepicker"
    }
  })], 1)]), _c('Col', {
    attrs: {
      "span": "20",
      "offset": "2"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between"
    }
  }, [_c('div', {
    staticClass: "chips chips--block-item chips--light",
    on: {
      "click": function click($event) {
        return _vm.setDefaultDate(7);
      }
    }
  }, [_vm._v(" Last 7 days ")]), _c('div', {
    staticClass: "chips chips--block-item chips--light",
    on: {
      "click": function click($event) {
        return _vm.setDefaultDate(14);
      }
    }
  }, [_vm._v(" Last 14 days ")]), _c('div', {
    staticClass: "chips chips--block-item chips--light",
    on: {
      "click": function click($event) {
        return _vm.setDefaultDate(30);
      }
    }
  }, [_vm._v(" Last 30 days ")]), _c('div', {
    staticClass: "chips chips--block-item chips--light",
    on: {
      "click": function click($event) {
        return _vm.setDefaultDate(90);
      }
    }
  }, [_vm._v(" Last 90 days ")])])]), _c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('br'), !_vm.selectedProperty.id ? _c('Alert', {
    attrs: {
      "show-icon": "",
      "type": "warning"
    }
  }, [_vm._v(" You need to select a property first ")]) : _c('div', {
    staticStyle: {
      "float": "right",
      "margin-bottom": "15px",
      "margin-right": "35px"
    }
  }, [_c('Button', {
    staticStyle: {
      "margin-right": "15px"
    },
    on: {
      "click": function click($event) {
        _vm.isDatepickerpoptipOpen = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('Button', {
    attrs: {
      "type": "primary",
      "disabled": _vm.selectedProperty.firstIngest === null || _vm.selectedProperty.lastIngest === null
    },
    on: {
      "click": _vm.applyDatepickerDate
    }
  }, [_vm._v(" Set date ")]), _c('br')], 1)], 1)]], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }