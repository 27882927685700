export default {
  global: {
    yes: 'Yes',
    no: 'No',
    submit: 'Submit',
    cancel: 'Cancel',
    save: 'Save',
    no_data: 'No specified data'
  },
  menu: {
    home: 'Home',
    overview: 'Overview',
    history: 'History',
    account: 'Account'
  },
  history: {
    title: 'Analysis history',
    last_n: 'Ten last analysis'
  },
  detail: {
    edit_control: 'Edit configuration',
    setup_summary: 'Résumé configuration',
    redo_control: 'Relaunch analyze',
    configuration: 'Characteristics',
    save_configuration: 'Save',
    recheck_configuration: 'Analyze',
    creative: 'Creative',
    summary: 'Ad Summary',
    detailed: 'Ad Details',
    control: 'Analyze VAST/VPAID Ad Url',
    start_control: 'Analyze Ad',
    results: 'Ad Analysis Report',
    new_control: 'New Ad Analysis',
    save_control: 'Save Ad Analysis Configuration',
    preview: 'Preview',
    name: 'name',
    vendors_typo: 'Domains per category',
    active_vendors: 'Domains found',
    campaign_name: 'Campaign name',
    advertiser: 'Advertiser',
    ads_found: 'Ads found',
    redirects_found: 'Redirects found',
    vpaid_viewability: 'VPAID viewability agent',
    vpaid_found: 'VPAID found',
    sale_types: 'Sale types',
    agency: 'Agency',
    nb: 'Nb',
    domain: 'Domain',
    campaign: 'Campaign',
    tradingdesk: 'Trading Desk',
    ads_count: 'Ads',
    redirects_count: 'Redirect hops',
    vpaid_count: 'VPAID',
    viewability_count: 'Viewability',
    last_checked_at: 'Last checked on ',
    vast_xml: 'VAST xml',
    vast_url: 'VAST url',
    last_check_date: 'Last analyzes',
    url_vast: 'VAST url',
    unknown_vendor: 'Unknown vendor',
    unknown_category: 'Unknown category',
    url_list: 'Complet list of url found',
    tracking_url: 'List of tracking events url',
    event_name: 'Event name',
    vendor: 'vendor',
    analyze: 'Analyze',
    ad_duration: 'Ad duration'
  }
}
