export default {
  isColumnFilterOpen: false,
  isFilterPanelOpen: false,
  categories: [
    { value: 'wrequests', label: 'Requests' },
    { value: 'wcookies', label: 'Cookies' },
    { value: 'wvendors', label: 'Vendors' }
  ],
  widgets: [
    // requests
    {
      value: 'requests00',
      group: 'wrequests',
      label: 'Total number of requests'
    },
    {
      value: 'requests01',
      group: 'wrequests',
      label: 'Secure and unsecure requests'
    },
    { value: 'requests02', group: 'wrequests', label: 'Top vendors requests' },
    {
      value: 'requests03',
      group: 'wrequests',
      label: 'Top vendors unsecured requests'
    },
    { value: 'requests04', group: 'wrequests', label: 'Vendors latency' },
    { value: 'requests05', group: 'wrequests', label: 'Top Vendors latency' },
    { value: 'requests06', group: 'wrequests', label: 'Flop Vendors latency' },
    {
      value: 'requests07',
      group: 'wrequests',
      label: 'History Nb requests per page'
    },
    {
      value: 'requests08',
      group: 'wrequests',
      label: 'Secure and unsecure requests ratio'
    },

    // cookies
    { value: 'cookies01', group: 'wcookies', label: 'All cookies' },
    { value: 'cookies02', group: 'wcookies', label: '1st Party' },
    { value: 'cookies03', group: 'wcookies', label: '3rd Party' },
    {
      value: 'cookies04',
      group: 'wcookies',
      label: 'All Excessive lifetime cookies'
    },
    {
      value: 'cookies08',
      group: 'wcookies',
      label: 'history split by 1st/3rd Party'
    },
    {
      value: 'cookies10',
      group: 'wcookies',
      label: 'Excessive lifetime cookies split 1st/3rd'
    },
    {
      value: 'cookies11',
      group: 'wcookies',
      label: 'Excessive lifetime cookies detail 1st'
    },
    {
      value: 'cookies12',
      group: 'wcookies',
      label: 'Excessive lifetime cookies detail 1st/3rd'
    },
    {
      value: 'cookies13',
      group: 'wcookies',
      label: 'Cookies split by category of vendor'
    },
    {
      value: 'cookies14',
      group: 'wcookies',
      label: 'Cookies list of vendors per category (Advertiser)'
    },
    {
      value: 'cookies15',
      group: 'wcookies',
      label: 'History of categories of vendors dropping cookies'
    },
    { value: 'cookies16', group: 'wcookies', label: '1st Party and last time' },
    { value: 'cookies17', group: 'wcookies', label: '3rd Party and last time' },
    { value: 'cookies18', group: 'wcookies', label: '1st/3rd Party' },
    {
      value: 'cookies21',
      group: 'wcookies',
      label: 'All Excessive lifetime cookies - DONUT'
    },
    {
      value: 'cookies22',
      group: 'wcookies',
      label:
        'History of categories of vendors dropping cookies (Day, Adverstiser)'
    },

    // vendors
    { value: 'vendors01', group: 'wvendors', label: 'All Vendors' },
    { value: 'vendors03', group: 'wvendors', label: 'Hardcoded Vendors' },
    {
      value: 'vendors05',
      group: 'wvendors',
      label: 'Piggybacking All vendors'
    },
    {
      value: 'vendors07',
      group: 'wvendors',
      label: 'Piggybacking Only vendors'
    },
    { value: 'vendors09', group: 'wvendors', label: 'Vendors By country' },
    {
      value: 'vendors10',
      group: 'wvendors',
      label: 'US Vendors not on privacy shield'
    },
    {
      value: 'vendors11',
      group: 'wvendors',
      label: 'US Vendors not on privacy shield - LIST'
    },
    { value: 'vendors12', group: 'wvendors', label: 'Whitelist analysis' },
    {
      value: 'vendors13',
      group: 'wvendors',
      label: 'Whitelist analysis detail (vendors not on whitelist)'
    },
    {
      value: 'vendors14',
      group: 'wvendors',
      label: 'Whitelist analysis detail (vendors on whitelist)'
    },
    {
      value: 'vendors16',
      group: 'wvendors',
      label: 'Vendors history split by type'
    },
    { value: 'vendors18', group: 'wvendors', label: 'Vendors by category' },
    {
      value: 'vendors21',
      group: 'wvendors',
      label: 'Vendors detail by category (Advertising)'
    },
    {
      value: 'vendors22',
      group: 'wvendors',
      label: 'Vendors history by category'
    },
    {
      value: 'vendors23',
      group: 'wvendors',
      label: 'Vendors detail by category and day'
    },
    {
      value: 'vendors24',
      group: 'wvendors',
      label: 'Hardcoded Vendors and their initiators'
    },
    {
      value: 'vendors25',
      group: 'wvendors',
      label: 'Piggybacking All Vendors and their initiators'
    },
    {
      value: 'vendors26',
      group: 'wvendors',
      label: 'Piggybacking Only Vendors and their initiators'
    },
    { value: 'vendors27', group: 'wvendors', label: 'Vendors split by type' },
    {
      value: 'vendors29',
      group: 'wvendors',
      label: 'Vendors details by country (France)'
    },
    {
      value: 'vendors32',
      group: 'wvendors',
      label: 'Vendors details daily by catagories'
    }
  ],
  propertiesIndex: [],
  properties: {},
  // NOTE: Demo only
  customersIndex: [],
  customers: {},
  customer: ''
}
