
export default [
  {
    path: '/admin/users',
    name: 'Admin users',
    component: () => import('@/views/Admin/Users/Index'),
    meta: {
      auth: true,
      admin: true,
      breadcrumb: [
        { name: 'Admin', link: '/admin' },
        { name: 'Users' }
      ]
    }
  },
  {
    path: '/admin/users/view/:id',
    name: 'Admin users view',
    component: () => import('@/views/Admin/Users/View'),
    meta: {
      auth: true,
      admin: true,
      breadcrumb: [
        { name: 'Admin', link: '/admin' },
        { name: 'Users', link: '/admin/users' },
        { name: 'View' }
      ]
    }
  },
  {
    path: '/admin/users/add',
    name: 'Admin users add',
    component: () => import('@/views/Admin/Users/Add'),
    meta: {
      auth: true,
      admin: true,
      breadcrumb: [
        { name: 'Admin', link: '/admin' },
        { name: 'Users', link: '/admin/users' },
        { name: 'Add' }
      ]
    }
  }
]
