export default [
  {
    path: '/admin',
    name: 'Admin base',
    redirect: {name: 'Overview dashbord'},
    meta: {
      auth: true,
      admin: true,
      dateRange: true
    }
  }
]
