export default {
  GET_COOKIE_PENDING (state, payload) {
    state.pending = payload.state
  },
  GET_COOKIE_ERROR (state, status) {
    state.error = status
  },
  GET_COOKIE_SUCCESS (state, payload) {
    state.success = payload.status
    state[payload.field] = payload.data
  },
  SET_OBSERVED_COOKIE_POLICY_LIST(state, list) {
    state.observedCookiePolicyList = list
    // can't use [...list] because spread operator only deep copy array
    // but nested object in are not deep copied
    // without a deep copy each changes on cookies for the draftCookieList are
    // also done for the observedCookiePolicyList because the object ref
    // is still the same
    state.draftCookiePolicyList = JSON.parse(JSON.stringify(list))
  },
  SET_DECLARED_COOKIE_POLICY_LIST(state, list) {
    state.declaredCookiePolicyList = list
  },

  MAP_VENDOR_TO_COOKIE(state, payload) {
    const cookieToUpdate = payload.cookie
    const vendor = payload.vendor
    state.draftCookiePolicyList.map((cookie) => {
      if (cookieToUpdate.hash_namehosttype === cookie.hash_namehosttype) {
        cookie.vendor = vendor
        if (vendor.id) cookie.vendor_id = vendor.id
      }
      return cookie
    })
  },

  REMOVE_DECLARED_COOKIE_IN_PRIVACY_LIST(state, cookie) {
    const removedCookie = state.draftCookiePolicyList.find(
      (elm) => elm.hash_namehosttype === cookie.hash_namehosttype
    )
    removedCookie.declared_in_tl = 0
  },

  QUALIFY_NEW_COOKIE(state, cookie) {
    let cookieToUpdate = state.draftCookiePolicyList.find(
      (elm) => elm.hash_namehosttype === cookie.hash_namehosttype
    )
    cookieToUpdate = Object.assign(cookieToUpdate, cookie)
    if (cookie?.exemption_declarations?.length) cookieToUpdate.is_exempted = 1
  },

  SET_SELECTED_CARD_KEY(state, seletedCardKey) {
    state.seletedCardKey = seletedCardKey
  },
}
