export const truncate = (str, length, ending) => {
  if (ending == null) {
    ending = '...'
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  } else {
    return str
  }
}

export const checkVendor = (vendor) => vendor || 'Under qualification'
