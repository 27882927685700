<template>
  <div
    id="layout"
    style="min-height:100%"
  >
    <div class="main-nav-bar">
      <div class="nav-bar-container">
        <div style="display:flex; align-items:center"
             v-if="!isVendorViewer"
        >
          <PropertySelectv2 v-if="!$route.path.includes('add') || !$route.path.includes('account') || !$route.path.includes('admin/vendors') || !$route.path.includes('admin/users') || !$route.path.includes('admin/properties')" />
        </div>
        <router-link
          tag="div"
          class="logo-box"
          to="/"
        />
        <div>
          <div style="display:flex; align-items:center">
            <router-link :to="{ path: '/news' }">
              <Badge
                :count="isNewMessages(messages)"
                style="margin-right:25px;cursor:pointer"
              >
                <Icon
                  type="ios-notifications"
                  size="18"
                />
              </Badge>
            </router-link>
            <router-link :to="{ name: 'Account' }">
              <Avatar
                data-tests="avatar-username"
                icon="md-contact"
                style="background-color: transparent"
              />
              <span style="color:#fff; ">{{ simplifyName(user.name) }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div style="padding-top:60px;">
      <SidebarMenu
        id="fa7-sidebar"
        @collapse="handleMenuCollapse"
      />

      <!--      <Col :style="{width: menuWidth(), minHeight:'100vh'}"-->
      <!--           v-if="!isVendorViewer"-->
      <!--           id="f7-nav-menu"-->
      <!--      >-->
      <!--        <SidebarMenu :style="{position:'fixed', width: !isCollapsed ? '240px' : '85px', overflow:'scroll', paddingBottom:'70px'}"-->
      <!--                     id="fa7-sidebar"-->
      <!--                     @collapse="handleMenuCollapse"-->
      <!--        />-->
      <!--      </Col>-->
      <!--      <Col :style="{width: contentWidth()}"-->
      <!--           id="f7-content"-->
      <!--      >-->
      <div
        id="f7-content"
      >
        <Row class="contentHeader"
             style="height:inherit!important;"
             v-if="!isVendorViewer"
        >
          <Col :span="24">
            <div v-if="!scopeQs">
              <Alert
                show-icon
                type="warning"
                data-tests="no-property-warning"
                style="margin-bottom:0"
              >
                {{ $t('global.no_property') }}
              </Alert>
            </div>
            <Bread v-else />
            <DaterangeSelectSlider v-if="false" />
          </Col>
          <!--          <Col :span="12">-->
          <!--             <PropertySelect v-if="!$route.path.includes('add') || !$route.path.includes('account') || !$route.path.includes('admin/vendors') || !$route.path.includes('admin/users') || !$route.path.includes('admin/properties')" />-->
          <!--          </Col>-->
        </Row>
        <Content class="contentWrapper"
                 style="height:inherit!important;"
        >
          <router-view
            @on-toggle-update-properties="handleToggleUpdateProperties"
          />
        </Content>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import PropertySelectv2 from '@/components/ui/PropertySelectv2'
import DaterangeSelectSlider from '@/components/ui/DaterangeSelectSlider'
import SidebarMenu from '@/components/ui/SidebarMenu'
import moment from 'moment'
import Bread from '@/components/ui/Bread'
import * as _ from 'lodash'


const OFFSET = 60

export default {
  components: {
    PropertySelectv2,
    Bread,
    SidebarMenu,
    DaterangeSelectSlider
  },
  data () {
    return {
      searchVendor: '',
      messages: [],
      vendorsFound: [],
      menuHover: false,
      websitesPopperState: false,
      numProperties: 0,
      showNavBar: true,
      lastScrollPosition: 0,
      scrollValue: 0,
      searchWebsites: '',
      propsSelected: [],
      isUpdatePropertiesPanel: false,
      isOptionsMenuOpen: false,
      optionsMenuClasses: [],
      isCollapsed: false,
      headerClasses: [],
      datePickerWrapperClasses: [],
      availableRangeValues:[],
      selectingRangeValues:[],
    }
  },
  watch: {
    scopeProperties () {
      this.updatePropertiesCounterBadge()
    },
    $route (route) {
      this.viewParams = {
        ...this.viewParams,
        dateRange: route.meta.dateRange || false,
        name: route.name || false
      }
    },
    scopeQs () {
      return this.notificationsGetInformationMessages({
        propertyId: this.scopeSelectedProperty.id,
        userId: this.user.id
      }).then(r => {
        this.messages = r.data
      }).catch(er => {
        return this.$Message.warning('endpoint: getInformationMessages, ' + er.data.data.message, 5000)
      })
    }
  },
  computed: {
    showMenuFull () {
      return this.menuHover
    },
    isVendorViewer (){
      return this.user.roles.find(elm => elm.alias === 'vendors-viewer')
    },
    // will check for rights in menu
    ...mapGetters({
      userHasRole: 'user/hasRole',
      user: 'user/user',
      userProperties: 'user/userProperties',
      scopeSelectedProperty: 'scope/selectedProperty',
      scopeSelectedDaterange: 'scope/selectedDaterange',
      scopeProperties: 'user/userProperties',
      scopeQs: 'scope/qs'
    }),
    checkSuperAdmin () {
      return this.userHasRole('super-admin')
    },
    selectedPropertiesCount () {
      return this.scopeProperties.length
    },
    filteredOptionsProperties () {
      return this.optionsProperties.filter((x) => {
        if (this.searchWebsites.length) {
          return (
            x.displayName
              .toLowerCase()
              .includes(this.searchWebsites.toLowerCase()) &&
            !this.isSelectedProp(x.propertyId)
          )
        } else {
          return x.displayName
            .toLowerCase()
            .includes(this.searchWebsites.toLowerCase())
        }
      })
    },
    facette_app_selected () {
      return localStorage.getItem('facette_app_selected')
    },
    isFacette () {
      return true
      // const sectionStorage = localStorage.getItem('facette_app_selected')
      // const url = this.$route.path
      // return sectionStorage.includes('video')
      //   ? false
      //   : !url.includes('video')
    },
    accessAdmin () {
      return this.userHasRole('super-admin')
    }
  },
  methods: {
    ...mapActions({
      optionsSetProperties: 'options/setProperties',
      notificationsGetInformationMessages: 'notifications/getInformationMessages',
      // optionsSetCustomers: 'options/setCustomers',
    }),
    menuWidth () {
      return !this.isCollapsed ? '240px' : '85px'
    },
    contentWidth () {
      if (this.user.roles.find(elm => elm.alias === 'vendors-viewer')) return '100%'
      return this.isCollapsed ? 'calc(100% - 85px)' : 'calc(100% - 240px)'
    },
    simplifyName (name) {
      const arr = name.split(' ')
      if (arr.length > 1) return arr[0] + '.' + arr[1].charAt(0) || ''
      return arr[0]
    },
    handleMenuCollapse (isCollapse) {
      this.isCollapsed = isCollapse
      const menu = document.getElementById('f7-nav-menu')
      const content = document.getElementById('f7-content')
      const sidebar = document.getElementById('fa7-sidebar')
      if (isCollapse) {
        menu.style.width = '85px'
        sidebar.style.width = '85px'
        content.style.width = "calc(100% - 85px)"
      } else {
        menu.style.width = '240px'
        sidebar.style.width = '240px'
        content.style.width = "calc(100% - 240px)"
      }
    },

    goToNews () {
      return this.$router.push('/notifications/news')
    },

    debounceInput: _.debounce(function (e) {
      const {searchVendor} = this
      searchVendor.length > 0 && this.fetchVendorsByName(searchVendor)
    }, 425),

    fetchVendorsByName (name) {
      return this.$axios.get('/api/vendors', {
        cache: {
          exclude: {query: true}
        },
        params: {
          q: name,
          limit: 10,
          onlyDetected: true,
          properties: [this.scopeSelectedProperty.id],
          period: this.scopeSelectedDaterange
        }
      }).then(resp => {
        if (resp.data.success) {
          this.vendorsFound = resp.data.data
        }
      })
    },
    goToRoute (name) {
      this.$router.push({name})
    },
    updatePropertiesCounterBadge () {
      this.numProperties = this.scopeProperties.length
    },
    setUserProperties () {
      this.optionsSetProperties(this.userProperties)
    },
    // getCustomers () {
    //   this.$axios
    //     .get('customers')
    //     .then((res) => {
    //       if (res.data.success) this.optionsSetCustomers(res.data.widget)
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //     })
    // },
    handleToggleUpdateProperties () {
      this.isUpdatePropertiesPanel = !this.isUpdatePropertiesPanel
    },
    onItemClick (event, item) {
      if (item.id === 'select-websites') {
        this.isUpdatePropertiesPanel = !this.isUpdatePropertiesPanel
      }
    },
    handleDrawerVisibilityChange (isVisible) {
      if (isVisible) {
        // Set search/input focus when drawer is open
        this.$nextTick(() => this.$refs.websitesSearchInputRef.focus())
        this.searchWebsites = ''
      }
    },
    onScroll () {
      if (window.pageYOffset < 0) {
        return
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
        return
      }
      this.showNavBar = window.pageYOffset < this.lastScrollPosition
      this.lastScrollPosition = window.pageYOffset
    },
    isNewMessages (messages) {
      const localKey = localStorage.getItem('fa7-last-message-opened') && JSON.parse(localStorage.getItem('fa7-last-message-opened'))
      if (!localKey) return messages.length
      let futurMessages = messages.filter(elm => {
        if (moment(elm.create).isAfter(moment(localKey))) {
          return elm.create
        }
      })
      return futurMessages.length
    }
  },
  mounted () {
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('scroll', this.onScroll)
    const viewportMeta = document.createElement('meta')
    viewportMeta.name = 'viewport'
    viewportMeta.content = 'width=device-width, initial-scale=1'
    document.head.appendChild(viewportMeta)
    this.updatePropertiesCounterBadge()
    this.propsSelected = [].concat(JSON.parse(JSON.stringify(this.scopeProperties)))
    this.setUserProperties()
    return this.notificationsGetInformationMessages({
      userId: this.user.id
    }).then(r => {
      this.messages = r.data
    }).catch(er => {
      return this.$Message.warning('endpoint: getInformationMessages, ' + er.data.data.message, 5000)
    })

    // this.availableRangeValues = [this.scopeSelectedProperty.firstIngest, this.scopeSelectedProperty.lastIngest]



  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>
<style lang="scss">
// #fa7-nav-menu::-webkit-scrollbar {
//   display: none;
// }
@import "../../assets/styles/main-layout";
#fa7-sidebar::-webkit-scrollbar {
  display: none;
}
#fa7-sidebar {
  position:fixed;
  z-index: 950;
  overflow-y: scroll;
  min-height: 100%;
  max-width:60px;
  width: 100%;
  transition: ease-in-out 125ms;
  & > .ivu-menu{
    min-height: 100%;
  }
  & .ivu-menu-submenu-title > span,
  & .ivu-menu-item > span{
    opacity:0;
  }
  & .ivu-menu-submenu .ivu-menu-item{
    padding-left :24px !important;
  }

  &:hover{
    max-width:240px;
    & .ivu-menu-submenu-title > span,
    & .ivu-menu-item > span{
      opacity:1;
    }

    & .ivu-menu-submenu .ivu-menu-item{
      padding-left :43px !important;
    }
  }
}
#f7-content {
  margin-left : 60px;
}
  @media (min-width: 1680px) {
    #fa7-sidebar {
      max-width:240px;
      & .ivu-menu-submenu-title > span,
      & .ivu-menu-item > span{
        opacity:1;
      }
      & .ivu-menu-submenu .ivu-menu-item{
        padding-left :43px !important;
      }
    }
    #f7-content {
      margin-left : 240px;
    }
  }

</style>
