export default {
  username: 'E-mail',
  password: 'Password',
  remember: 'Remember me',
  forgotPassword: 'password forgotten',
  login: 'Log in',
  logout: 'Log out',
  errUsrInvalid: 'Invalid email',
  errUsrMissing: 'Fill in your email',
  errPwdMissing: 'Fill in your password',
  errFormInvalid: 'Invalid form'
}
