
export default {
  /**
   * Execute an api call and follows these steps.
   * 1. Set "pending" fiel to true in the state, meaning the request is pending.
   * 2. On response:
   *    -> if there is an error set "error" field to true in the state.
   *    -> otherwise set "success" field to true in the state. And apply callback parameter on response.
   * 3. Finally set "pending" field to false, meaning that request is finished.
   * @param {Function} commit -> Function provided by vuex to mutate the store's state
   * @param {Promise} promise    -> Promise of the api request.
   * @param {Function} cb     -> Callback to modify the response before returning it.
   * @returns {Promise}
   */
  makeCall: function ({commit, getters}, { namespace, promise, uid, callback }) {
    const upperNamespace = namespace.toUpperCase()
    // uid from component, it identify which compoenent did the call and give an id for the mutations
    const idFromParams = uid
    commit(`${namespace}/GET_${upperNamespace}_PENDING`, {state: true, id: idFromParams}, {root: true})
    return promise.then(res => {
      if (res.fromStore) {
        return res.data
      }
      /*
        The call promise can resolve and axios response object or an array of axios response.
        Response object contains a "success" field that defines if request succeeded.
      */
      const multipleResSuccess = res instanceof Array && res.every(r => r.data.success)
      const singleResSuccess = !(res instanceof Array) && res.data.success

      /**
       * Handle errors when there is an array of promises (multiples call in one action)
       */
      const isMultipleRes = !!(res instanceof Array)

      if (isMultipleRes) {
        res.forEach(response => {
          if (!response.data.success) {
            throw response
          }
        })
      } else {
        if (!res.data.success) {
          if (status !== 200) throw res
        }
      }

      if (singleResSuccess || multipleResSuccess) {
        const stateField = genericFieldCreator(res)
        const payload = {
          status: true,
          field: stateField,
          data: res ? res.data : {}
        }
        commit(`${namespace}/GET_${upperNamespace}_SUCCESS`, payload, {root: true})
        return callback ? callback(res) : res.data
      }

      /*
        -> multipleResSuccess: will be true if we're handling array of response and each response has "success" field set to true.
        -> singleResSuccess: will be true if we're handle a single response
      */
    }).catch(err => {
      commit(`${namespace}/GET_${upperNamespace}_ERROR`, true, {root: true})
      // commit('GET_VENDOR_ERROR', true)
      throw err
    }).finally(() => {
      commit(`${namespace}/GET_${upperNamespace}_PENDING`, {state: false, id: idFromParams}, {root: true})
      // commit('GET_VENDOR_PENDING', false)
    })
  }
}
function genericFieldCreator (res) {
  //TODO: Retirer les doubles appels qui renvoient des tableaux relou
  // res n'est pas consistant il devrait être un objet dans tous les cas
  if (!res || res instanceof Array) return ''
  const { config } = res
  return config.url + '?' + JSON.stringify(config.params)
}