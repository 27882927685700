import ApiCollectsConnector from './../api/collects'

// Create restful api/vendor object
const apiCollects = new ApiCollectsConnector('collects')

// Generic dispatch('makeCall') with the module namespace for mutations
const proxy = ({fn, call, callback, uid}) => fn('makeCall', {namespace: 'collects', promise: call, uid, callback: callback}, {root: true})

export default {
  getCollects: function ({dispatch, rootGetters}, {params, uid = 0}) {
    // define parameters if function was not call with object parameters
    if (!params) params = arguments[1]
    return proxy({fn: dispatch, call: apiCollects.get(rootGetters['user/token'], params), uid})
  },
}
