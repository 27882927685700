<template>
  <Breadcrumb v-if="breadcrumbList.length">
    <BreadcrumbItem
      :to="breadcrumb.link"
      v-for="(breadcrumb, idx) in breadcrumbList"
      :key="idx"
    >
      {{ breadcrumb.name }}
    </BreadcrumbItem>
  </Breadcrumb>
  <span v-else>&nbsp;</span>
</template>

<script>
export default {
  name: 'Bread',
  data () {
    return {
      breadcrumbList: []
    }
  },
  mounted () {
    this.updateBreadcrumbList()
  },
  watch: {
    '$route' () {
      this.updateBreadcrumbList()
    }
  },
  methods: {
    updateBreadcrumbList () {
      this.breadcrumbList = []
      let splittedPath = this.$route.path.split('/')
      let splittedName = this.$route.name.split(' ')
      let currentPath = ''
      console.log('name', splittedName)
      for (let i = 0; i < splittedPath.length; i++) {
        if ((splittedPath[i].trim()) !== '') {
          this.breadcrumbList.push({
            link: currentPath + '/' + splittedPath[i],
            name: splittedName[i - 1]
          })
          currentPath += '/' + splittedPath[i]
        }
      }
    }
  }
}
</script>
