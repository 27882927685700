import { WIDGET_KEYS } from "../../views/Cookies/CookiePolicy/constants"

export default {
  pending: null,
  error: null,
  success: null,
  observedCookiePolicyList: [], // this is immutable, its gotten from the api
  declaredCookiePolicyList: [], // this is immutable, its gotten from the api
  draftCookiePolicyList: [], // we can change this by action.
  seletedCardKey: WIDGET_KEYS.total,
}
