import query from 'qs'
import moment from 'moment'
import i18n from '@/i18n/index'
import {DATE_PICKER_MAX_DATE} from "@/config"

export const focus = state => state.focus

export const qs = state => {
  if (Object.keys(state.selectedProperty).length > 0 && state.selectedDaterange.length > 0) {
    return query.stringify({
      properties: [state.selectedProperty.id],
      startdate: moment(state.selectedDaterange[0]).format('YYYY-MM-DD'),
      enddate: moment(state.selectedDaterange[1]).format('YYYY-MM-DD')
    })
  }
}

export const properties = state => Object.values(state.properties)

export const saveSelectedDatarange = state => state.saveSelectedDaterange

export const selectedDaterange = state => state.selectedDaterange

export const selectedProperty = state => {
  // Last (max) ingest date is limited to 2024-09-09
  let lastIngestDateString = DATE_PICKER_MAX_DATE
  // To avoid user selecting dates where there are no scans
  // We have to use the most distant past date time
  // between property's last ingest and the date max limit (2024-09-09).
  if (state.selectedProperty?.lastIngest) {
    lastIngestDateString = moment.min([
      moment(state.selectedProperty.lastIngest), moment(DATE_PICKER_MAX_DATE)
    ])
  }

  return {
    ...state.selectedProperty,
    lastIngest: lastIngestDateString
  }
}

export const maximumDaterange = _ => {
  return [moment().subtract(6, 'month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
}

export const selectableDaterange = state => {
  const {selectedProperty} = state
  const dateFormat = 'YYYY-MM-DD'
  const nowDate = moment()
  const minDate = moment(nowDate).subtract(6, 'month')
  const formatDateRange = (start, end) => [moment(start).format(dateFormat), moment(end).format(dateFormat)]

  if (Object.keys(selectedProperty).length === 0) {
    return formatDateRange(minDate, nowDate)
  }

  const {firstIngest, lastIngest} = selectedProperty

  if (moment(firstIngest).isSame(lastIngest)) {
    return formatDateRange(firstIngest, lastIngest)
  }
  if (selectedProperty.lastIngest && selectedProperty.firstIngest) {
    if (moment(firstIngest).isBefore(minDate, 'day')) {
      return moment(lastIngest).isBefore(minDate, 'day') ? null : formatDateRange(minDate, lastIngest)
    }
    return formatDateRange(firstIngest, lastIngest)
  }
  return formatDateRange(minDate, nowDate)
}

export const isDaterangeSelected = state => {
  const {selectedDaterange} = state

  return selectedDaterange && selectedDaterange.length === 2 && state.selectedDaterange.every(date => date)
}

export const isOneDaySelectedDaterange = (_, getters) => {
  const {isDaterangeSelected, selectableDaterange} = getters

  return isDaterangeSelected && selectableDaterange && moment(selectableDaterange[0]).isSame(selectableDaterange[1], 'day')
}

export const selectedDaterangeText = (state, getters) => {
  const {selectedDaterange} = state
  const {selectableDaterange, isDaterangeSelected} = getters

  if (isDaterangeSelected && selectableDaterange) {
    if (moment(selectableDaterange[0]).isSame(selectableDaterange[1])) {
      return `${selectableDaterange[0]}`
    }
    return `${selectedDaterange[0]} - ${selectedDaterange[1]}`
  }
  return i18n.t('global.no_daterange_btn')
}
