export default [
  {
    path: '/active-trackers',
    name: 'Trackers',
    component: () => import('@/views/Cookies/ActiveTrackers'),
    meta: {
      auth: true,
      dateRange: true
    }
  },
  {
    path: '/trackers',
    name: 'Trackers-by-scenario',
    component: () => import('@/views/Cookies/TrackersByScenario'),
    meta: {
      auth: true,
      dateRange: true
    }
  },
  { 
    path: '/trackers/cookie-policy',
    name: 'Cookie-Policy',
    component: () => import('@/views/Cookies/CookiePolicy/Index.vue'),
    meta: {
      auth: true,
      dateRange: true
    }
    
  },
  {
    path: '/trackers-list',
    name: 'Trackers-list',
    component: () => import('@/views/Cookies/List'),
    meta: {
      auth: true,
      dateRange: true
    }
  }
]
