import { api as env } from '@/config.js'
import axios from 'axios'
import store from './../index'

import { cacheAdapterEnhancer } from 'axios-extensions'

// const httpClient = axios.create({
//   adapter: cacheAdapterEnhancer(axios.defaults.adapter)
// });

class ApiConnector {
  /**
   * Define the section name to call the good api
   * eg => for vendors it will call /api/vendors
   * @param {String} nameSpace the section name
   */
  constructor (nameSpace, options = {}) {
    this.nameSpace = nameSpace
    this.options = Object.assign({
      rootUrl: env.root,
      axios: {
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
      }
    }, options)
  }

  _seekStateData(params, url, method) {
    // disable cache response
    return false
    // const mappedNamespace = {
    //   cookies: 'cookie',
    //   vendors: 'vendor',
    //   notifications: 'notifications',
    //   properties: 'property',
    //   users: 'user'
    // }
    // // if (method !== 'get') {
    // //   const namespace = mappedNamespace[this.nameSpace]
    // //   console.log('unstore data for', namespace)

    // //   for (const key of Object.keys(store.state[namespace])) {
    // //     if (key.includes('/api/')) store.commit('DELETE_ITEM', {field: key, namespace: namespace})
    // //   }
    // // }
    // try {
    //   const field = url + '?' + JSON.stringify(params)
    //   const namespace = mappedNamespace[this.nameSpace]
    //   const dedicatedStore = store.state[namespace] 
    //   if (dedicatedStore[field]) return dedicatedStore[field]
    // }
    // catch (er) {
    //   return false
    // }
    
  }
  /**
   * Define the axios base Call
   *
   * @param {String} method       the request method 'get', 'post', 'patch', 'put', 'delete'
   * @param {Object} params       the endpoint parameters
   * @param {Obhject} data        the request data
   * @param {Number} id           an id
   * @returns {Promise}           resolve the axios promise
   */
  _axios ({token, method, params = {}, data = {}, axiosOptions = {}, id = null, specialUrl = false, csv = false}) {
    const deepCopy = Object.assign({}, this.options.axios)
    const options = Object.assign(deepCopy, axiosOptions)
    options.headers.Authorization = 'Bearer ' + token

    // Define if there is a specific url to use and if the url should have '/id' at the end
    let url = this.options.rootUrl + 'api/' + this.nameSpace
    if (specialUrl) {
      if (id) {
        url = this.options.rootUrl + specialUrl + '/' + id
      } else {
        url = this.options.rootUrl + specialUrl
      }
    } else if (id) {
      url = url + '/' + id
    }
    if (csv) {
      url += '.csv'
      // const query = new URLSearchParams(params).toString()
      // return window.open(url + '?' + query, '_blank')
    }
    const isDataFromStore = false
    if (isDataFromStore && method === 'get') {
      console.log('axios call cancelled, data serve from vuex')
      return Promise.resolve({data: isDataFromStore, fromStore: true})
    } else {
      return axios({
        method: method,
        url: url,
        params,
        data,
        ...options
      })
        .catch(err => {
          return err.response
        })
    }
    
  }

  /**
     * ask RESTFUL api
     * @param   {String}    token             access token
     * @param   {Object}    params            The api call parameters
     * @param   {Object}    data              The data object
     * @param   {String}    id                The id
     * @return  {Promise}                     Resolves when request end
     */
  get (token, params = {}) {
    return this._axios({token, params, method: 'get'})
  }
  put (token, id, data = {}) {
    return this._axios({token, id, data, method: 'put'})
  }
  post (token, data = {}) {
    return this._axios({token, data, method: 'post'})
  }
  view (token, id, params = {}) {
    return this._axios({token, id, params, method: 'get'})
  }
  delete (token, id) {
    return this._axios({token, id, method: 'delete'})
  }
  export (token, params) {
    params.limit = 10000
    const options = {
      headers: {
        'Accept': 'application/octet-stream'
      },
      responseType: 'blob'
    }
    return this._axios({token, params, method: 'get', csv: true, axiosOptions: options})
  }
}

export default ApiConnector
