import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'

import map from './map'
import options from './options'
import scope from './scope'
import alerts from './alerts'
import admin from './admin_old'
import user from './user'
import vendor from './vendor'
import notifications from './notifications'
import cookie from './cookie'
import collects from './collects'
import property from './property'
import video from './video'

Vue.use(Vuex)
const state = {
  loading: false
}
const mutations = {
  SET_LOADING: function (state, loading) {
    state.loading = loading
  },
  DELETE_ITEM (state, {field, namespace}) {
    delete state[namespace][field]
  }
}

const getters = {
  hash: state => input => {
    let hash = 0
    let len = input.length
    for (let i = 0; i < len; i++) {
      hash = ((hash << 5) - hash) + input.charCodeAt(i)
      hash |= 0 // to 32bit integer
    }
    return hash
  }
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    video,
    user,
    collects,
    map,
    options,
    scope,
    alerts,
    vendor,
    notifications,
    admin,
    cookie,
    property
  }
})

export default store
console.log('store created', store)
