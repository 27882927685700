export const setCurrentProperty = (state, currentProperty) => {
  state.currentProperty = currentProperty
}

export const setScope = (state, scope) => {
  state.scope = scope
}

export const setAlertType = (state, alertType) => {
  state.alertType = alertType
}

export const setNotificationTypes = (state, types) => {
  state.notificationTypes = types
}
