import axios from 'axios'
import { api } from './../../config'

/**
 * AuthServices
 *
 * Build an instance of AuthServices
 *
 * @return {Object}     AuthServices instance with method to video api
 */
export default function AuthServices () {
  const headers = {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
  return {
    /**
     * Find user by email and password
     *
     * @param   {Object}    data              The credentials
     * @param   {Object}    params            The api call parameters
     * @return  {Promise}                     Resolves when request end
     */
    login (data, params = {}) {
      headers['Authorization'] = false
      return axios({
        method: 'post',
        url: api.root + 'api/auth',
        clearCacheEntry: true,
        data,
        headers
      })
    },

    /**
     * Find user by token
     *
     * @param   {String}    token             the token
     * @param   {Object}    params            The api call parameters
     * @return  {Promise}                     Resolves when request end
     */
    auth (token, params = {}) {
      headers['Authorization'] = 'Bearer ' + token
      return axios({
        method: 'get',
        url: api.root + 'api/auth',
        clearCacheEntry: true,
        headers
      })
    },

    /**
     * Find user by id
     *
     * @param   {Number}    id              The id
     * @param   {Object}    params            The api call parameters
     * @return  {Promise}                     Resolves when request end
     */
    view (id, token, params = {}) {
      headers['Authorization'] = 'Bearer ' + token
      return axios({
        method: 'get',
        url: api.root + 'api/users/view/' + id,
        headers
      })
    },

    /**
     * find user's properties
     *
     * @param {Object} data   //
     * @param {Object} params the api call parameters
     * @returns {Promise}     resolve the api call
     */
    fetchUserProperties (data, token, params = {}) {
      headers['Authorization'] = `Bearer ${token}`

      return axios({
        method: 'get',
        params,
        url: api.root + 'properties',
        data,
        headers
      })
    }
  }
}
