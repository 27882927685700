import MainLayout from '@/components/layouts/MainLayout'
import account from './account/account'
import adminDomains from './admin/adminDomains'
import adminProperties from './admin/adminProperties'
import adminUsers from './admin/adminUsers'
import adminVendor from './admin/adminVendor'
import admin from './admin/index'
import cookies from './cookies/cookies'
// import _cookies from './cookies/_cookies'
import login from './login/login'
import notifications from './notifications/notifications'
import overview from './overview/overview'
import vast from './vast/vast'
import vendors from './vendors/vendors'
import complianceIndex from './complianceIndex/complianceIndex'
export default [
  ...login,
  {
    path: '/',
    component: MainLayout,
    children: [
      ...complianceIndex,
      ...overview,
      ...admin,
      ...adminProperties,
      ...adminUsers,
      ...adminDomains,
      ...adminVendor,
      ...account,
      ...cookies,
      // ..._cookies,
      ...notifications,
      ...vendors,
      ...vast
    ]
  },
  { path: "/:pathMatch(.*)*", redirect: to => {return { path: '/'}} }
]
