import groupBy from 'lodash/groupBy'

export const properties = (state) => {
  if (state.customer) {
    return state.propertiesIndex
      .map((i) => state.properties[i])
      .filter((p) => p.isEnabled && p.customer_id === state.customer)
  } else {
    return state.propertiesIndex
      .map((i) => state.properties[i])
      .filter((p) => p.isEnabled)
  }
}
export const propertiesByValues = (state) => (values) => {
  const properties = state.propertiesIndex.map((i) => state.properties[i])
  return values
    ? values.map((v) => properties.find((p) => p.propertyId === v))
    : []
}
export const widgets = (state) => state.widgets
export const categories = (state) => state.categories
export const widgetsByCategory = (state) => (categoryId) =>
  categoryId ? groupBy(state.widgets, 'group')[categoryId] : []
// NOTE: Demo only
export const customers = (state) =>
  state.customersIndex.map((i) => state.customers[i])
export const customer = (state) => state.customer

export const isColumnFilterOpen = (state) => state.isColumnFilterOpen
export const isFilterPanelOpen = (state) => state.isFilterPanelOpen