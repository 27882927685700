import ApiConnector from './index'

class ApiVendorConnector extends ApiConnector {
  getVendorcategories (token, params) {
    return this._axios({token, method: 'get', params, specialUrl: 'api/categories'})
  }
  getVendorlist (token, params) {
    return this._axios({token, method: 'get', params, specialUrl: 'api/declareCmpvendorlists'})
  }
  getVendorObservedVendorList (token, params) {
    return this._axios({token, method: 'get', params, specialUrl: 'api/declareCmpvendorlists/observedVendors'})
  }
  getVendorDeclaredVendors (token, params) {
    return this._axios({token, method: 'get', params, specialUrl: 'api/declareCmpvendorlists/declaredVendors'})
  }
  postVendorToVendorlist (token, data) {
    return this._axios({token, method: 'post', data, specialUrl: 'api/declareCmpvendorlists/add'})
  }
  removeVendorFromVendorlist (token, data) {
    return this._axios({token, method: 'post', data, specialUrl: 'api/declareCmpvendorlists/delete'})
  }
  getVendorTCFDeclaration (token, params) {
    return this._axios({token, method: 'get', specialUrl: 'iab/getAllInformation?iabVendorId=' + params.id})
  }
  editVendorcategory (token, data, id) {
    return this._axios({token, method: 'put', data, specialUrl: 'api/categories/' + id})
  }
  getOptions (token, option, params = { limit: 500 }) {
    return this._axios({token, method: 'get', params, specialUrl: 'api/' + option})
  }
  getVendorAliases (token, params) {
    return this._axios(({token, method: 'get', params, specialUrl: 'api/vendoraliases'}))
  }
  postVendorAliases (token, data) {
    return this._axios(({token, method: 'post', data, specialUrl: 'api/vendoraliases'}))
  }
  deleteVendorAliases (token, aliasId) {
    return this._axios(({token, method: 'delete', specialUrl: 'api/vendoraliases/' + aliasId}))
  }
  getVendorDomains (token, params = {}) {
    return this._axios(({token, method: 'get', params, specialUrl: 'api/vendordomains'}))
  }
  getConsentProvider (token, params = {}) {
    return this._axios({token, method: 'get', params, specialUrl: 'api/getConsentProvider'})
  }
}

export default ApiVendorConnector
