import VideoApi from './../api/video'
import ApiVastconfigsConnector from './../api/vast'
const apiVastconfigs = new ApiVastconfigsConnector('vastconfigs')
const proxy = ({fn, call, callback, uid}) => fn('makeCall', {namespace: 'video', promise: call, uid, callback: callback}, {root: true})


export default {
  getVastConfigs: function ({dispatch, rootGetters}, {params, uid = 0}) {
    // define parameters if function was not call with object parameters
    if (!params) params = arguments[1]
    return proxy({fn: dispatch, call: apiVastconfigs.get(rootGetters['user/token'], params), uid})
  },

  vasConfigById: function ({dispatch, rootGetters}, {id}) {
    return proxy({fn: dispatch, call: apiVastconfigs.view(rootGetters['user/token'], id)})
  },

  getSaleTypes: function ({dispatch, rootGetters}) {
    return proxy({fn: dispatch, call: apiVastconfigs.getSaleTypes(rootGetters['user/token'])})
  },

  searchByAgncy: function ({dispatch, rootGetters}, {params}) {
    return proxy({fn: dispatch, call: apiVastconfigs.searchByAgncy(rootGetters['user/token'], params)})
  },

  searchByAdv: function ({dispatch, rootGetters}, {params}) {
    return proxy({fn: dispatch, call: apiVastconfigs.searchByAgncy(rootGetters['user/token'], params)})
  },

  view ({ state, dispatch }, vastData, params = {}) {
    console.log('view', vastData)
    const { id, url } = vastData
    if (id === null && !!url) {
      console.log('dispatch TESTVAST')
      return dispatch('testVAST', { url })
    }
    return VideoApi()
      .get({ id, params })
      .then((resp) => {
        if (!resp.data || !resp.data.success) {
          return 'error'
        }
        return resp.data
      })
      .catch((er) => {
        // handle errors
      })
  },

  /**
   * Make api call to get all sale type
   *
   * @param {Object} _ the store context
   *
   * @returns {Promise} resolve the promise
   */
  testVAST ({rootGetters}, url) {
    const token = rootGetters['user/token']
    return VideoApi().test(url, {}, token).then((resp) => {
      if (resp.status === 201) {
        return resp.data
      }
    })
  },

  /**
   * make the api call to send vast data to the API
   *
   * @param {Object} context the store context
   * @param {Object} vastData the data to send
   *
   * @returns {Promise} resolve the promise when api respond
   */
  add ({ dispatch }, vastData) {
    const params = {}
    // vastData.url = vastData.url
    // delete vastData.config.vastUrl
    return VideoApi()
      .post(vastData, params)
      .then((resp) => {
        if (resp.data.success === false) {
          return resp.data
        }
        console.log('add response', resp)
        return dispatch('view', resp.data.data)
      })
      .catch((er) => {
        // handle errors
        console.log(er)
      })
  },

  /**
   * make the api call to update vast data
   *
   * @param {Object} context the store context
   * @param {Object} vastData the data to send
   *
   * @returns {Promise} resolve the promise when api respond
   */
  edit ({ dispatch }, vastData) {
    console.log('edit', vastData)
    return VideoApi()
      .patch(vastData)
      .then((response) => {
        console.log(response)
        if (response.data.success) {
          return dispatch('view', response.data.data)
        }
      })
      .catch((er) => {
        // handle errors
        console.log(er)
      })
  },

  /**
   * make the api call to process vast data
   *
   * @param {Object} context the store context
   * @param {String} vastId  the vats ID
   *
   * @returns {Promise} resolve the promise when api respond
   */
  put ({ dispatch, state }, vastId) {
    const id = vastId
    return VideoApi()
      .put(vastId)
      .then((response) => {
        return { response, id: id }
      })
      .catch((er) => {
        // handle errors
        console.log(er)
      })
  }
}
