var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "facettes-sidebar"
  }, [_c('Menu', {
    attrs: {
      "active-name": _vm.currentActiveMenu,
      "open-names": []
    },
    on: {
      "on-select": function onSelect(name) {
        return _vm.$router.push(name);
      }
    }
  }, _vm._l(_vm.menu, function (menuK, key) {
    return _c('div', {
      key: key,
      attrs: {
        "name": key
      }
    }, [_vm._l(menuK, function (menuItem, keyItem) {
      return _c('div', {
        key: keyItem + 'item',
        attrs: {
          "name": menuItem.title
        }
      }, [menuItem.subMenu ? [_c('Submenu', {
        staticStyle: {
          "backgound": "#f7f7f7"
        },
        attrs: {
          "name": menuItem.title
        }
      }, [_c('template', {
        slot: "title"
      }, [_c('i', {
        class: menuItem.icon
      }), !_vm.isCollapse ? _c('span', [_vm._v(_vm._s(menuItem.title))]) : _vm._e()]), _vm._l(menuItem.subMenu, function (dropdownItem, dropdownKey) {
        return _c('MenuItem', {
          key: dropdownKey + 'dropdown-item',
          attrs: {
            "name": dropdownItem.href
          }
        }, [_c('i', {
          class: dropdownItem.icon
        }), !_vm.isCollapse ? _c('span', [_vm._v(_vm._s(dropdownItem.title))]) : _vm._e(), dropdownItem.status && !_vm.isCollapse ? _c('Tag', {
          staticClass: "new-tag",
          attrs: {
            "color": dropdownItem.status.color
          }
        }, [_vm._v(" " + _vm._s(dropdownItem.status.text) + " ")]) : _vm._e()], 1);
      })], 2)] : _c('MenuItem', {
        style: {
          textAlign: _vm.isCollapse ? 'center' : 'left'
        },
        attrs: {
          "name": menuItem.href
        }
      }, [_c('i', {
        class: menuItem.icon
      }), !_vm.isCollapse ? _c('span', [_vm._v(_vm._s(menuItem.title))]) : _vm._e(), menuItem.status && !_vm.isCollapse ? _c('Tag', {
        staticClass: "new-tag",
        attrs: {
          "color": menuItem.status.color
        }
      }, [_vm._v(" " + _vm._s(menuItem.status.text) + " ")]) : _vm._e()], 1)], 2);
    }), key < menuK.length ? _c('Divider') : _vm._e()], 2);
  }), 0)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }