export default [
  {
    path: '/admin/domains',
    name: 'Admin domains',
    component: () => import('@/views/Admin/Domains/Index'),
    meta: {
      auth: true,
      admin: true,
      breadcrumb: [
        { name: 'Admin', link: '/admin' },
        { name: 'Domains' }
      ]
    }
  },
  {
    path: '/admin/domains/view',
    name: 'Admin domains view',
    component: () => import('@/views/Admin/Domains/View'),
    meta: {
      auth: true,
      admin: true,
      breadcrumb: [
        { name: 'Admin', link: '/admin' },
        { name: 'Domains', link: '/admin/domains' },
        { name: 'View' }
      ]
    }
  },
  {
    path: '/admin/domains/add',
    name: 'Admin domains add',
    component: () => import('@/views/Admin/Domains/Add'),
    meta: {
      auth: true,
      admin: true,
      breadcrumb: [
        { name: 'Admin', link: '/admin' },
        { name: 'Domains', link: '/admin/domains' },
        { name: 'Add' }
      ]
    }
  }

]
