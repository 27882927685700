export default [
  {
    path: '/admin/properties',
    name: 'Admin properties',
    component: () =>
      import('@/views/Admin/Properties/Index'),
    meta: {
      auth: true,
      admin: true,
      breadcrumb: [
        { name: 'Admin', link: '/admin' },
        { name: 'Properties' }
      ]
    }
  },
  {
    path: '/admin/properties/view',
    name: 'Admin properties view',
    component: () =>
      import('@/views/Admin/Properties/View'),
    props: ({ query }) => ({
      propertyId: query.id
    }),
    meta: {
      auth: true,
      admin: true,
      breadcrumb: [
        { name: 'Admin', link: '/admin' },
        { name: 'Properties', link: '/admin/properties' },
        { name: 'View' }
      ]
    }
  },
  {
    path: '/admin/properties/create',
    name: 'Admin properties create',
    component: () =>
      import('@/views/Admin/Properties/View'),
    meta: {
      auth: true,
      admin: true,
      breadcrumb: [
        { name: 'Admin', link: '/admin' },
        { name: 'Properties', link: '/admin/properties' },
        { name: 'Create' }
      ]
    }
  }
]
