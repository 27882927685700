var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.breadcrumbList.length ? _c('Breadcrumb', _vm._l(_vm.breadcrumbList, function (breadcrumb, idx) {
    return _c('BreadcrumbItem', {
      key: idx,
      attrs: {
        "to": breadcrumb.link
      }
    }, [_vm._v(" " + _vm._s(breadcrumb.name) + " ")]);
  }), 1) : _c('span', [_vm._v(" ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }