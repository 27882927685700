<template>
  <div>
    <div id="particles-js" />
    <div class="auth-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import 'particles.js'

export default {
  name: 'Index',
  mounted () {
    // if (env === 'prod') this.$ga.page('/login')
    window.particlesJS('particles-js', {
      'particles': {
        'number': {
          'value': 40,
          'density': {
            'enable': true,
            'value_area': 600
          }
        },
        'color': {
          'value': '#ffffff'
        },
        'shape': {
          'type': 'circle',
          'stroke': {
            'width': 0,
            'color': '#000000'
          },
          'polygon': {
            'nb_sides': 5
          },
          'image': {
            'src': 'img/github.svg',
            'width': 100,
            'height': 100
          }
        },
        'opacity': {
          'value': 1,
          'random': false,
          'anim': {
            'enable': false,
            'speed': 1,
            'opacity_min': 1,
            'sync': false
          }
        },
        'size': {
          'value': 3,
          'random': true,
          'anim': {
            'enable': false,
            'speed': 40,
            'size_min': 0.1,
            'sync': false
          }
        },
        'line_linked': {
          'enable': true,
          'distance': 150,
          'color': '#ffffff',
          'opacity': 1,
          'width': 1
        },
        'move': {
          'enable': true,
          'speed': 4,
          'direction': 'none',
          'random': false,
          'straight': false,
          'out_mode': 'out',
          'bounce': false,
          'attract': {
            'enable': false,
            'rotateX': 600,
            'rotateY': 1200
          }
        }
      },
      'interactivity': {
        'detect_on': 'canvas',
        'events': {
          'onhover': {
            'enable': true,
            'mode': 'repulse'
          },
          'onclick': {
            'enable': true,
            'mode': 'push'
          },
          'resize': true
        },
        'modes': {
          'grab': {
            'distance': 140,
            'line_linked': {
              'opacity': 1
            }
          },
          'bubble': {
            'distance': 400,
            'size': 40,
            'duration': 2,
            'opacity': 8,
            'speed': 3
          },
          'repulse': {
            'distance': 200,
            'duration': 0.4
          },
          'push': {
            'particles_nb': 4
          },
          'remove': {
            'particles_nb': 2
          }
        }
      },
      'retina_detect': false
    })
  },
  destroyed () {
    const PjsFns = window.pJSDom[0].pJS.fn
    window.cancelRequestAnimFrame(PjsFns.checkAnimFrame)
    window.cancelRequestAnimFrame(PjsFns.drawAnimFrame)
    PjsFns.particlesEmpty()
    PjsFns.canvasClear()
    PjsFns.vendors.destroypJS()
    window['pJSDom'] = []
  }
}
</script>

<style scoped>
    #particles-js {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: -1;
        background: #E3E9E7;
    }

    .auth-container {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }
</style>