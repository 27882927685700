/*
  VAST module router
 */
export default [
  {
    path: '/vast',
    component: () => import('@/views/Vast/Index'),
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'Vast',
        component: () => import('@/views/Vast/Overview'),
        meta: { auth: true, admin: true }
      },
      {
        path: 'vast_config/:id',
        name: 'Vast config page',
        component: () => import(/* webpackChunkName: "vast" */ '@/views/Vast/VastConfig'),
        meta: { auth: true, admin: true }
      }
    ]
  },
  {
    path: 'vast-inventory-list',
    name: 'Vast inventory list',
    component: () => import(/* webpackChunkName: "vast" */ '@/views/Vast/InventoryList'),
    meta: { auth: true, admin: true }
  },
  {
    path: 'vast-payload-list',
    name: 'Vast payload list',
    component: () => import(/* webpackChunkName: "vast" */ '@/views/Vast/PayloadList'),
    meta: { auth: true, admin: true }
  }
]
