export default {
  welcome_launcher: "Welcome to the Facettes Application Manager",
  no_property: "Start by selecting a property then a date range",
  home: "Home",
  vendors: "Vendors",
  vendors_admin: "Admin vendors",
  categories: "Categories",
  cookies: "Cookies",
  trackers: "Trackers",
  notifications: "Notifications",

  settings: "Account",
  domains: "Admin domains",
  users: "Admin users",
  poc: "POC UI by scenarios",
  properties: "Admin properties",
  vast: "Vast",
  vast_history: "Vast history",
  select_ws: "Select website",
  no_data: "No Data Found",
  dl_csv: "Download .csv",
  from: "from",
  to: "to",
  yes: "Yes",
  no: "No",
  report: "Report for",
  about: "about",
  download: "Download",
  active: "Active",
  view_all: "View: all",
  update: "Update",
  reset: "Reset",
  loading: "Loading...",
  none: "None",
  red: "red",
  edit: "Edit",
  create: "Create",
  inactive: "Inactive",
  save: "Update",
  no_update: "No updates available",
  cancel: "cancel",
  see: "See",
  count: "Count",
  description: "Description",
  property_select_placeholder: "Select a property",
  no_daterange_btn: "Select a date range",
  more_details: "Click on chart to get more granular informations",
  last_data: "Last collect data",
  compliance_index: {
    daily_value: "COMPLIANCE INDEX DAILY VALUE",
    value: "Compliance index value",
    details: "Detailed results",
    rules: "What is the compliance index",
    value_desc: `The compliance score is calculated based on the vendors and trackers detected during our automated scans. <br> (i.e : are there any third party trackers dropped before consent or despite consent refusal)`,
    score_desc: "Score description",
    index: "Compliance index",
    if_value_below: "If compliance value < ",
    if_value_above: "if Compliance index value is above",
    value_built_on: "value is built on :",
    r1: "The amount of vigilance of non-exempt vendors depositing first party trackers without consent,",
    r2: "The amount of vigilance of non-exempt vendors depositing third party trackers without consent,",
    r3: "The amount of vigilance of non-exempt vendors depositing first party trackers despite consent refusal,",
    r4: "The amount of vigilance of non-exempt vendors depositing third party trackers despite consent refusal,",
    r5: "The amount of vigilance of active vendors.",
    nb: "NB: If the vendor of a tracker is unknown, the vigilance is set to ",
  },
  admin: {
    collect_error_title:
      "The collect validation has failed because of he following reasons",
    collect_error_r1: "anti-bot guard",
    collect_error_r2: "invalid url",
    collect_in_progress: "The property validation is in progress",
  },
  property_select: {
    enabled_only: "Show only enabled properties",
    collect_enabled_only: "Show only collection enabled properties",
    enabled_collect: "Collection enabled",
    disabled_collect: "Collection disabled",
    fresh_collect_data: "Fresh data",
    stale_collect_data: "Stale data",
    nodata_collect: "No data",
    save_checkbox: "Save date range",
    clear_button: "Clear",
    submit_button: "Submit",
    periods: {
      custom: "Custom",
      three_days: "Last 3 days",
      one_week: "Last week",
      two_weeks: "Last 2 weeks",
      one_month: "Last month",
      two_months: "Last 2 months",
    },
    alerts: {
      collect_period: "No data to show out of this range.",
      date_format: "Please format as: YYYY-MM-DD.",
      start_date_missing: "Please select a start date.",
      end_date_missing: "Please select an end date.",
      daterange_missing: "Please select a daterange.",
      wrong_order: "Start date cannot be after end date.",
    },
    no_data_message: "No data found with this website",
    period_label: "Period",
    from_label: "From",
    to_label: "To",
  },

  overview: {
    last_collected_data: "Last collect : ",
    ranged_collected_data: "Selected date range data : ",
    tc_status: "TC String decoder",
    tc_status_desc:
      "TC String identified using the IAB TCF v2 CMP api through the different scenarios.",
    declared_vendors_cmp: "Declared vendors in CMP",
    non_detected_vendors_in_cmp: "Non Declared vendors detected",
    consent: "consent",
    missing: "Missing",
    presence: "Presence of",
    no_cmp_data: "No CMP data",
  },

  validation: {
    char_min: "Minimum 3 characters required",
    char_min2: "Minimum 2 characters required",
    invalid: "The form is invalid, please check your entries and try again.",
    email: "Invalid email address format",
    num: "The value must be numeric",
    invalid_url: "Invalid url",
    success: "Success",
    pass_updated: "Your password has been changed",
    changed: "Your changes have been saved!",
    error: "An error has occurred...",
    serv_error: "Server sent back an error...",
    collect_frequencry: "The collect frequency is required",
    max_url: "URL lenght must be between 1 and 500",
    confirm: "Please confirm your action",
    enter_name: "Please enter a name",
    country: "Country is required",
    category: "Category is required",
    url: "Please enter a valid URL",
  },

  index: {
    excellent: "Excellent",
    good: "Good",
    average: "Fair",
    ngood: "Poor",
    absolute: "Bad",
  },

  user: {
    account: "Account",
    logged_as: "Logged in as",
    update_pass: "Update password",
    signout: "Sign out",
    reset_pass: "Reset my password",
    change_pass: "Change my password",
    back_login: "Back to login",
    watch_box: "Watch your inbox",
    box_success:
      "If this email is valid, we will send you the following steps to reset your password very soon. You can now close this page",
    box_error: "Something went wrong with our server, please try again.",
    current_pass_required: "Your current password is required",
    new_pass_required: "Your new password is required",
    properties: "Properties",
    new: "New user",
  },

  scenarios: {
    allVendors: "Active vendors",
    allCookies: "Active trackers",
    without_choice: "Without explicit choice",
    with_choice: "With explicit consent ",
    refusal_choice: "Despite refusal",

    cookies_desc: {
      without_choice:
        "Detected cookies and trackers without explicit choice. This includes technical / exempted cookies, but also cookies and trackers detected before any user action",
      with_choice:
        "Detected cookies and trackers after explicit consent to all IAB TCF features and purposes",
      refusal_choice:
        "Detected cookies and trackers despite user refusal of IAB TCF features and purposes",
    },
  },

  property: {
    ncollected: "Not collected",
    collect_frequency: "Collect frequency",
    new: "New property",
    enable: "Enabled",
    disable: "Disabled",
    enable_ui: "Enable in UI",
    enable_ui_c:
      "When enabled, the property and the related collected data will be shown in Facettes",
    enable_collect: "Enable bot collection",
    enable_collect_c:
      "When enabled, the bot will collect trackers on the website",
    enable_auto_detect: "Enable CMP detection",
    enable_auto_detect_c:
      "When enabled, the bot will try to detect the CMP on the website",
    display_name: "Displayed name in Facettes (must be unique)",
    industry: "Industry",
    select_industry: "Select industry",
    user_a: "User agent",
    user_a_eg: "If empty it will be 'Agno/YY.MM.0', i.e.",
    select_frequency: "Select frequency",
    max_url: "Max urls",
    max_url_c: "Amount of maximum crawled urls by collect",
  },

  domain: {
    add: "Add domain",
    orphan: "Show Orphans domains only",
    found_for: "Domains found for",
    all_w: "all websites",
    level: "Domains level",
    no_entries_request: "No entries match your request",
    all_level: "All levels",
    level1: "Level 1",
    level2: "Level 2",
    level3: "Level 3",
    domain_number: "Number of domains",
    tier: "Tier",
    pattern: "Pattern",
    sub_lvl: "Sub-Level-Domain",
    select_link_vendor: "Select below the vendor to link to the domain : ",
    sub_for: "Sub-domains for",
    check_sub: "Check sub-domains to link to the vendor",
    sub_in: "Sub-domains in",
    no_domains: "No domains found",
    not_linked:
      "are currently not linked to a vendor. Therefore, they will be linked automatically to the selected vendor, unless you uncheck them.",
  },

  cookie: {
    compliance_index: "Compliance index",
    compliance_index_desc:
      "Composite index calculated from compliance risks, click on to see details",
    all_detected: "All trackers detected",
    exempted_cookies: "Exempted trackers",
    persistent: "persistent",
    session: "session",
    first_party: "1st party",
    first_party_t: "1st party trackers",
    third_party: "3rd party",
    third_party_t: "3rd party trackers",
    all_scenarios: "All scenarios",
    by_scenario: "By scenario",
    cookies_drop_before_choice: "Cookies dropped before user choice",
    count: "Trackers count : ",
    filter: "Filter by cookie name",
    cookies_drop_unqualified_state: "Unqualified state",
  },

  vendor: {
    category_vigilance: "Category vigilance :",
    count: "Vendors count",
    active_explicit_consent: "Active vendors with explicit consent ",
    active_explicit_consent_desc:
      "Total number of active vendors on your website with explicit consent during the selected period. These vendors fired javascripts or pixels and possibly dropped some cookies.",
    exempted_vendors: "Exempted vendors",
    exempted_vendors_desc:
      "Total number of exempted active vendors found during the selected period",
    active_no_consent: "Active vendors without explicit choice",
    active_no_consent_desc:
      "Total number of active vendors on your website without explicit choice during the selected period. These vendors fired javascripts or pixels and possibly dropped some cookies.",
    active_despite_refusal: "Active vendors despite refusal",
    active_despite_refusal_desc:
      "Total number of active vendors on your website despite refusal  during the selected period. These vendors fired javascripts or pixels and possibly dropped some cookies.",
    hardcoded_count: "Hardcoded vendors",
    hardcoded_desc:
      "Unique number of vendors found in the source code of your website or fired by the Tag Management System used on your website. You have full control on these vendors and you can decide which one to keep or remove.",
    count_desc: "Amount of active vendor detected",
    overview: "Overview",
    unqualifed: "Active vendors under qualification",
    novendor: "No vendor",
    management: "Vendors management",
    active: "Active vendors",
    vendors_in_cmp: "Active vendors declared in the CMP",
    vendors_not_in_cmp: "Active vendors not declared in the CMP",
    v_under_qualification: "This vendor is under qualification",
    vendors_under_qualification: "Vendors under qualification",
    watchlited_vendors: "Watchlisted vendors",
    whitelisted_vendors: "Whitelisted vendors",
    setup_rules: "Setup vendor rules",
    progress: "Qualification in progress",
    tcf2: "TCF 2.0",
    declared: "declared",
    ndeclared: "not declared",
    in_tcf2: "in TCF 2.0",
    tag_manager: "Tag management system",
    in_privacy: "In privacy policy",
    new: "New vendor",
    qualify_vendor_domains:
      "Please qualify the different domains belonging to the vendor",
    vendor_adstxt_cmp_white:
      "A vendor is generally considered whitelisted when it is present in the CMP and in the ads.txt",
    vendor_adstxt_cmp_watch:
      "When a vendor is not present in the CMP or ads.txt, it is advisable to put it on watchlist in order to monitor its behavior",
    vendor_if_watchlist_domain:
      "If a vendor has at least one domain under watchlist then all the vendor will be under watchlist",
    privacy: "Privacy",
    contact: "General contact",
    contact_l: "Legal contact",
    founded: "Founded",
    update: "Edit vendor",
    last_u: "Last update",
    trust_i: "Trust index",
    tcf_index: "TCF vigilance index",
    adp: "In Google Adtech Provider list",
    adp_common_used: "In Google Adtech Provider list (commonly used)",
    activity: "Activity",
    in_ads: "In ads.txt",
    in_cmp: "In CMP",
    cookies_drop: "Cookies dropped",
    collect_period: "Collect period",
    requests: "Requests done",
    request: "Request done",
    consent: "Consent",
    flexible: "Flexible",
    legit_interest: "Legitimate interest",
    info: "Informed",
    default_tree: "Default tree",
    will_b_auto_affect: "Will be auto-affected",
    enter_name: "Enter the vendor name",
    aliases: "Aliases",
    add_aliases: "Press enter to add aliases",
    add_description: "Enter the vendor description",
    add_contact: "Enter the website contact",
    add_contact_l: "Enter the vendor website contact",
    url: "Website URL",
    add_url: "Enter the vendor website url",
    location: "Location",
    add_location: "Enter the vendor location",
    founded_at: "Founded date",
    add_founded: "Enter the vendor founded date",
    check_domains_link: "Check domain to link vendor",
    lock_iabv2Id_description: "TFC 2.0 is locked",
    vendor_categories: "Vendor Categories",
  },

  notification: {
    active: "Active",
    archive: "Archive",
    see_more: "See more",
    no_notifications: "No notification available",
    archive_notice: "Notification successfully archived",
    touchpoint: "Action points",
    vigilance_index: "Vigilance index :",
    notif_type: "Notification type :",
    notif_date: "Notification date :",
    notif_id: "Notification ID :",
    issue: "Encountered problem",
    detail: "Details",
    detail_notif: "Detail notification",
    corrective: "Corrective Action",
    reference: "Reference",
    actions: "Actions : ",
    cnil: "CNIL",
    see_facettes: "See data in Facettes",
    dl_report: "Download csv report",
    archive_notif: "Archive notification",
  },

  graph: {
    website: "Website",
    direct_call_only: "Direct calls only",
    direct_n_tms: "Direct & TMS/CMP calls",
    direct_n_indirect: "Direct & indirect calls",
    indirect_call_only: "Indirect calls only",
    indirect_call_only_to_vendor:
      "Indirect calls Only to vendor under qualification",
    view_vendor: "View vendor card",
    vendor_not_found: "Vendor not found in the graph.",
    requests: "Requests",
    from_website: "From website:",
    from_vendors: "From vendors:",
    initiators: "initiators: ",
    find_vendor: "Find vendor by name",
    find_tracker: "Find tracker by name",
    active_on_period: "Active on period",
  },

  filters: {
    in_tcf: "In TCF only",
    in_adstxt: "In Ads.txt only",
    in_gatp: "In GATP",
    all: "All",
    in: "In",
    out: "Out",
    cmp_filter: "CMP filter",
    is_exempted: "Exemption",
    status_filter: "Status filter",
    watchlist: "Watchlist",
    whitelist: "Whitelist",
    update: "Update",
    under_qualification: "Under qualification",
    domains_level: "Domains level",
    all_levels: "All levels",
    level_1: "Level 1",
    level_2: "Level 2",
    level_3: "Level 3",
    only_show_orphans: "Show orphans only",
  },

  time: {
    day: "Day",
    days: "Days",
    week: "Week",
    month: "Month",
    months: "Months",
    select_range: "Select a date range",
  },
  countries: {
    france: "France",
    belgium: "Belgium",
    germany: "Germany",
    great_britain: "Great Britain",
    spain: "Spain",
    united_states: "United States",
  },
  collects: {
    capture_time:
      "Collects duration per page after scenario executions (in seconds)",
    browser_v: "Browser version",
    browser: "Browser",
  },
  table: {
    activity: "Activity",
    lastActivity: "Last activity",
    name: "Name",
    domain: "Domain",
    manage: "Manage",
    created: "Created",
    modified: "Modified",
    topcatgeory: "Top category",
    level: "Level",
    id: "Id",
    category: "Category",
    party: "1st/3rd party",
    persistence: "Persistence",
    lifetime: "Lifetime",
    type: "Type",
    vendor: "Vendor",
    country: "Country",
    origin: "Origin",
    action: "Action",
    first_ingest: "First ingest",
    last_ingest: "Last ingest",
    collect_status: "Collection status",
    collect_frequency: "Collection frequency",
    collect_country: "Collect country",
    collect_maxurls: "Max. urls",
    collect_monthlycost: "Est. Monthly cost",
    validation_status: "Verification status",
    tcf: "TCF",
    cmp: "CMP",
    adstxt: "Ads.txt",
    status: "Activation status",
    vigilance: "Trust index",
    qualify: "Qualify",
    roles: "Roles",
    last_login: "Last login < 30 days",
    lock_iabv2Id: "Prevent TCF 2.0 modification",
  },
  tcfv2_f_and_p: {
    purposes: {
      name_p_1: 'Store and/or access information on a device',
      name_p_2: 'Select basic ads',
      name_p_3: 'Create a personalised ads profile',
      name_p_4: 'Select personalised ads',
      name_p_5: 'Create a personalised content profile',
      name_p_6: 'Select personalised content',
      name_p_7: 'Measure ad performance',
      name_p_8: 'Measure content performance',
      name_p_9: 'Apply market research to generate audience insights',
      name_p_10: 'Develop and improve products',

    }
  },

  cookie_policy: {
    widget_names: {
      total: "total number of trackers detected",
      qualified: "trackers qualified",
      unqualified: "trackers unqualified",
      exempted: "trackers exempted",
    },
    widget_alert: {
      total:
        "The table below displays all trackers that were detected on your website for the selected period.",
      qualified: "You can export the list of trackers that are only qualified",
      unqualified:
        "In order to generate the cookie policy you need to associated purposes to those trackers",
      exempted:
        "The table below displays all exempted trackers that were detected on your website for the selected period",
    },
  },
}
