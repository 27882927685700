import Vue from 'vue'
import { setup } from 'axios-cache-adapter'
// import { api, env } from '@/config'
import { api } from '@/config'
import store from '@/store'
import router from '../router'

const MAX_REQUESTS_COUNT = 20 // Max concurrent requests
const INTERVAL_MS = 10 // Time between each call
let PENDING_REQUESTS = 0 // Calls tracker

export default () => {
  // // TODO: Cancel axios calls after each route change
  // const cancelSource = axios.CancelToken.source()
  // store.commit('settings/setCancelSource', cancelSource)
  let instance = setup({
    // cancelToken: cancelSource.token,
    cache: {
      maxAge: 5 * 60 * 1000,
      exclude: {
        query: false
      }
    },
    baseURL: api.root,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })

  instance.interceptors.request.use(
    (config) => {
      return new Promise((resolve, reject) => {
        let interval = setInterval(() => {
          if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
            PENDING_REQUESTS++
            clearInterval(interval)
            const token = store.getters['user/token']
            if (token) {
              config.headers = {
                ...config.headers,
                Authorization: `Bearer ${token}`
              }
            }
            resolve(config)
          }
        }, INTERVAL_MS)
      })
    },
    (err) => {
      return Promise.reject(err)
    }
  )

  /**
   * Axios Response Interceptor
   */
  instance.interceptors.response.use(
    (response) => {
      PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
      return Promise.resolve(response)
    },
    (error) => {
      // If expired token = sign out
      if (error.response.data.message === 'Expired token') {
        console.log('redir:auth')

        store.dispatch('user/logout')
        store.commit('scope/RESET_SCOPE')
        router.push({ name: 'AuthLogin' })
      }

      PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
      return Promise.reject(error)
    }
  )

  Vue.prototype.$axios = instance
}
