export default {
  username: 'E-mail',
  password: 'Mot de passe',
  remember: 'Se souvenir de moi',
  forgotPassword: 'Mot de passe oublié',
  login: 'Se connecter',
  logout: 'Se déconnecter',
  errUsrInvalid: 'Email non valide',
  errUsrMissing: 'Renseignez votre email',
  errPwdMissing: 'Renseignez votre mot de passe',
  errFormInvalid: 'Formulaire invalide'
}
