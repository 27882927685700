export default {
  GET_VENDOR_PENDING (state, payload) {
    state.pending = payload.state
  },
  GET_VENDOR_ERROR (state, status) {
    state.error = status
  },
  GET_VENDOR_SUCCESS (state, payload) {
    // allow only 15mbit on vendor state
    // if (_getMemory(state)/1000000 < 15 ) 
    state[payload.field] = payload.data
    state.success = payload.status
  },
  DISPLAY_CATEGORY_HOVER (state, value) {
    state.displayCategoryHelper = value
  },
  SET_DECLARED_VENDORS (state, vendors) {
    state.vendorListDeclaredVendors = vendors
  },
  DEDUPLICATE_VENDORS (state) {
    const ids = []
    state.draftVendorList = JSON.parse(JSON.stringify(state.draftVendorList)).map(vnd => {
      if (ids.includes(vnd.id) && vnd.id > 0) {
        vnd.duplicate = true
      }
      ids.push(vnd.id)

      return vnd
    })
  },
  UPDATE_UNKNOW_VENDOR (state, vendor) {    
    // apply filter to avoid displaying multiple time same vendors base on host or aliases
    if (state.draftVendorList.find(elm => elm.id === vendor.id)) {
      vendor.duplicate = true
    }
    state.draftVendorList = state.draftVendorList.map(vnd => {
      if ((vendor.host && vendor.host === vnd.host )|| (vendor.alias && vendor.alias === vnd.alias)) {
        return vendor
      } else {
        return vnd
      }
    })
  },
  SET_DRAFT_VENDORLIST (state, vendors) {
    state.draftVendorList = JSON.parse(JSON.stringify(vendors))
    const amountDeclared = vendors.filter(vendor => vendor.detected_in_cmp || vendor.declared_in_vl).length
    const amountNonDeclared = vendors.length - amountDeclared
    state.baseVendorListState = {
      amountDeclared: amountDeclared,
      amountNonDeclared: amountNonDeclared,
      list: vendors
    }
  },
  PUSH_NEW_VENDOR_TO_DRAFT_VENDORLIST (state, vendor) {
    state.draftVendorList.push(vendor)
  },
  PUSH_TO_DRAFT_VENDORLIST (state, val) {
    let vendorToUpdate = null
    if (!val.id) {
      const vendorHost = val.host
      vendorToUpdate = state.draftVendorList.find(vendor => vendor.host === vendorHost)
    } else {
      const vendorId = val.id
      vendorToUpdate = state.draftVendorList.find(vendor => vendor.id === vendorId)
    }
    vendorToUpdate.detected_in_cmp = 1
    vendorToUpdate.declared_in_vl = 1
  },
  REMOVE_FROM_DRAFT_VENDORLIST (state, val) {
    let vendorToUpdate = null
    if (!val.id) {
      const vendorHost = val.host
      vendorToUpdate = state.draftVendorList.find(vendor => vendor.host === vendorHost)
    } else {
      const vendorId = val.id
      vendorToUpdate = state.draftVendorList.find(vendor => vendor.id === vendorId)
    }
    vendorToUpdate.detected_in_cmp = 0
    vendorToUpdate.declared_in_vl = 0
  }
}

function _getMemory(object) {

  var objectList = []
  var stack = [ object ]
  var bytes = 0

  while ( stack.length ) {
    var value = stack.pop()

    if ( typeof value === 'boolean' ) {
      bytes += 4
    }
    else if ( typeof value === 'string' ) {
      bytes += value.length * 2
    }
    else if ( typeof value === 'number' ) {
      bytes += 8
    }
    else if
    (
      typeof value === 'object'
            && objectList.indexOf( value ) === -1
    )
    {
      objectList.push( value )

      for( var i in value ) {
        stack.push( value[ i ] )
      }
    }
  }
  return bytes

}