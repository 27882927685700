export default [
  {
    path: '/admin/vendors',
    name: 'Vendors admin',
    component: () => import('@/views/Admin/Vendors/Index'),
    meta: {
      auth: true,
      admin: true,
      breadcrumb: [
        { name: 'Admin', link: '/admin' },
        { name: 'Vendors' }
      ]
    }
  },
  {
    path: '/admin/vendors/:uuid',
    name: 'Vendors admin view',
    component: () => import('@/views/Vendors/FicheVendor/index'),
    meta: {
      dateRange: true
    }
  },
  {
    path: '/admin/vendors-category',
    name: 'Vendor admin category',
    component: () => import('@/views/Admin/Vendors/Sub_category'),
    meta: {
      auth: true,
      admin: true,
      breadcrumb: [
        { name: 'Admin', link: '/admin' },
        { name: 'Vendors category'},
      ]
    }
  },
  // {
  //   path: '/admin/vendors/view',
  //   name: 'Vendors admin view',
  //   component: () => import('@/views/Admin/Vendors/View'),
  //   props: ({ query }) => ({
  //     vendorId: query.id
  //   }),
  //   meta: {
  //     auth: true,
  //     admin: true,
  //     breadcrumb: [
  //       { name: 'Admin', link: '/admin' },
  //       { name: 'Vendors', link: '/admin/vendors' },
  //       { name: 'View' }
  //     ]
  //   }
  // },
  {
    path: '/admin/add-vendor',
    name: 'Vendors admin add',
    component: () => import('@/views/Admin/Vendors/Add'),
    meta: {
      auth: true,
      admin: true,
      breadcrumb: [
        { name: 'Admin', link: '/admin' },
        { name: 'Vendors', link: '/admin/vendors' },
        { name: 'Add' }
      ]
    }
  }
]
