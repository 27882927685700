export default [
  // {
  //   path: '/vendors',
  //   name: 'Vendors',
  //   meta: {
  //     auth: true,
  //     dateRange: true
  //   },
  //   component: () => import('@/views/Vendors/index'),
  //   children: [
      
  //     {
  //       name: 'Vendors management',
  //       path: 'rules',
  //       component: () => import('@/views/Vendors/Tabs/VendorsRules'),
  //       meta: {
  //         auth: true,
  //         dateRange: true
  //       }
  //     },
     
  //   ]
  // },
  {
    name: 'Overview',
    path: '/vendors',
    component: () => import('@/views/Vendors/Tabs/Overview'),
    meta: {
      auth: true,
      dateRange: true
    }
  },
  {
    path: '/vendors/categories',
    name: 'Vendors categories',
    component: () => import('@/views/Vendors/Categories'),
    meta: {
      auth: true
    }
  },
  
  {
    path: '/vendors/vendorlist',
    name: 'Vendorlist',
    component: () => import('@/views/Vendors/VendorList/index'),
    meta: {
      auth: true,
      dateRange: true
    }
  },
  {
    name: 'Vendors active',
    path: '/active-vendors',
    component: () => import('@/views/Vendors/Tabs/ActiveVendors'),
    meta: {
      auth: true,
      dateRange: true
    }
  },
  {
    name: 'Vendors list',
    path: '/vendors/vendors-management',
    component: () => import('@/views/Vendors/Tabs/VendorsRules'),
    meta: {
      auth: true,
      dateRange: true
    }
  },
  {
    path: '/vendors/:uuid',
    name: 'Vendors view',
    component: () => import('@/views/Vendors/FicheVendor/index'),
    meta: {
      auth: true,
      dateRange: true
    }
  }
  // {
  //   path: '/vendors/:name',
  //   name: 'Vendors view',
  //   component: () => import('@/views/Vendors/FicheVendor/index'),
  //   meta: {
  //     auth: true,
  //     dateRange: true
  //   }
  // }
]
