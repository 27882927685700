export default {
  pending: null,
  error: null,
  success: null,
  draftVendorList: [],
  vendorListDeclaredVendors: [],
  baseVendorListState: {
    list: []
  },
  displayCategoryHelper: true,
  riskColor: {
    A: '#81C327',
    B: '#6BAAD8',
    C: '#FFBD41',
    D: '#FF7850',
    E: '#D55D78'
  },
  alphaRiskColor: {
    A: '#81C32730',
    B: '#6BAAD830',
    C: '#FFBD4130',
    D: '#FF785030',
    E: '#D55D7830'
  },
  options: [
    'categories',
    'countries'
    /*,
    'groups',
    'subcategories'
    */
  ]
}
