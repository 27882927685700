var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "min-height": "100%"
    },
    attrs: {
      "id": "layout"
    }
  }, [_c('div', {
    staticClass: "main-nav-bar"
  }, [_c('div', {
    staticClass: "nav-bar-container"
  }, [!_vm.isVendorViewer ? _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [!_vm.$route.path.includes('add') || !_vm.$route.path.includes('account') || !_vm.$route.path.includes('admin/vendors') || !_vm.$route.path.includes('admin/users') || !_vm.$route.path.includes('admin/properties') ? _c('PropertySelectv2') : _vm._e()], 1) : _vm._e(), _c('router-link', {
    staticClass: "logo-box",
    attrs: {
      "tag": "div",
      "to": "/"
    }
  }), _c('div', [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        path: '/news'
      }
    }
  }, [_c('Badge', {
    staticStyle: {
      "margin-right": "25px",
      "cursor": "pointer"
    },
    attrs: {
      "count": _vm.isNewMessages(_vm.messages)
    }
  }, [_c('Icon', {
    attrs: {
      "type": "ios-notifications",
      "size": "18"
    }
  })], 1)], 1), _c('router-link', {
    attrs: {
      "to": {
        name: 'Account'
      }
    }
  }, [_c('Avatar', {
    staticStyle: {
      "background-color": "transparent"
    },
    attrs: {
      "data-tests": "avatar-username",
      "icon": "md-contact"
    }
  }), _c('span', {
    staticStyle: {
      "color": "#fff"
    }
  }, [_vm._v(_vm._s(_vm.simplifyName(_vm.user.name)))])], 1)], 1)])], 1)]), _c('div', {
    staticStyle: {
      "padding-top": "60px"
    }
  }, [_c('SidebarMenu', {
    attrs: {
      "id": "fa7-sidebar"
    },
    on: {
      "collapse": _vm.handleMenuCollapse
    }
  }), _c('div', {
    attrs: {
      "id": "f7-content"
    }
  }, [!_vm.isVendorViewer ? _c('Row', {
    staticClass: "contentHeader",
    staticStyle: {
      "height": "inherit!important"
    }
  }, [_c('Col', {
    attrs: {
      "span": 24
    }
  }, [!_vm.scopeQs ? _c('div', [_c('Alert', {
    staticStyle: {
      "margin-bottom": "0"
    },
    attrs: {
      "show-icon": "",
      "type": "warning",
      "data-tests": "no-property-warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.no_property')) + " ")])], 1) : _c('Bread'), false ? _c('DaterangeSelectSlider') : _vm._e()], 1)], 1) : _vm._e(), _c('Content', {
    staticClass: "contentWrapper",
    staticStyle: {
      "height": "inherit!important"
    }
  }, [_c('router-view', {
    on: {
      "on-toggle-update-properties": _vm.handleToggleUpdateProperties
    }
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }