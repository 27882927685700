import { getCookiePolicyByState } from "./utils"

export default {
  // these getters  are  not immutable getters
  // getObservedCookiePolicyList: (state) => state.observedCookiePolicyList,
  // getExemptedObservedCookiesPolicyList: (state) =>
  //   state.observedCookiePolicyList.filter(({ is_exempted }) => is_exempted),

  // Observed
  getObservedCookiesPolicyListByState: (state) =>
    getCookiePolicyByState(state.observedCookiePolicyList),

  // Draft
  getDraftCookiesPolicyListByState: (state) =>
    getCookiePolicyByState(state.draftCookiePolicyList),

  getDeclareCookiesPolicyListByState: (state) => {
    const declaredCookies = state.draftCookiePolicyList.filter(
      ({ declared_in_tl }) => declared_in_tl
    )
    return getCookiePolicyByState(declaredCookies)
  },

  // // These getters are mutable is mutables
  // getDraftCookiePolicyList: (state) => state.draftCookiePolicyList,
  // getExemptedDraftCookiePolicyList: (state) =>
  //   state.draftCookiePolicyList.filter(({ is_exempted }) => is_exempted),

  getSeletedCardKey: (state) => state.seletedCardKey,
}
